// features/settingSlice.js
// Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken, logoutUser } from '../utils/auth';

// Base URL for API requests
// const baseURL = 'https://sf-collection-api-0f31b037d478.herokuapp.com';
const baseURL = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  adminSettings: [],
  mobileSettings: [],
  integrationSettings: [],
  adminSettingUpdate: [],
  mobileSettingUpdate: [],
  integrationSettingUpdate: [],
  resetAdminSettings: [],
  status: 'idle',
  error: null,
};

//Async thunk for Admin Settings
export const fetchAdminSetting = createAsyncThunk('adminSetting/getAdminSettings', async () => {
  const response = await axios.get(`${baseURL}/api/adminSetting/adminSettings`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  return response.data;
});

// Async Thunk for Update admin settings
export const updateAdminSetting = createAsyncThunk('adminSetting/adminSettings', async (requestBody) => {
  const response = await axios.post(`${baseURL}/api/adminSetting/adminSettings`, requestBody, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  return response.data;
});

// Async thunk for Reset admin Settings
export const resetAdminSettings = createAsyncThunk('adminSetting/resetAdminSettings', async () => {
  const response = await axios.put(`${baseURL}/api/adminSetting/resetAdminSettings`, {}, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  return response.data;
});

// Async thunk for Mobile Settings
export const fetchMobileSetting = createAsyncThunk('adminSetting/getMobileSettings', async () => {
    const response = await axios.get(`${baseURL}/api/adminSetting/getMobileSettings`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  });
  
  // Async Thunk for Update mobile settings
  export const updateMobileSetting = createAsyncThunk('adminSetting/mobileSettings', async (requestBody) => {
    const response = await axios.post(`${baseURL}/api/adminSetting/mobileSettings`, requestBody, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  });

  //Async Thunk for Integration Settings
export const fetchIntegrationSetting = createAsyncThunk('adminSetting/getIntegrationSettings', async (type) => {
    const response = await axios.get(`${baseURL}/api/adminSetting/getIntegrationSettings`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  });

  //Async Thunk for UpdateIntegration Settings
  export const updateIntegrationSetting = createAsyncThunk('adminSetting/create', async (requestBody) => {
    const response = await axios.post(`${baseURL}/api/adminSetting/create`, {data:requestBody}, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return response.data;
  });

// Slice for managing settings state
const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    //Admin Setting
      .addCase(fetchAdminSetting.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAdminSetting.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.adminSettings = action.payload;
      })
      .addCase(fetchAdminSetting.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        if(action.error.code === "ERR_BAD_REQUEST"){
          logoutUser();
        }
      })

      .addCase(updateAdminSetting.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateAdminSetting.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.adminSettingUpdate = action.payload;
        //console.log('action.payload', action.payload)
      })
      .addCase(updateAdminSetting.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        if(action.error.code === "ERR_BAD_REQUEST"){
          logoutUser();
        }
      })

      .addCase(resetAdminSettings.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(resetAdminSettings.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.resetAdminSetting = action.payload;  // Store the result of the reset
      })
      .addCase(resetAdminSettings.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        if (action.error.code === 'ERR_BAD_REQUEST') {
          logoutUser();
        }
      })

      //Mobile Setting
      .addCase(fetchMobileSetting.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMobileSetting.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.mobileSettings = action.payload;
      })
      .addCase(fetchMobileSetting.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        if(action.error.code === "ERR_BAD_REQUEST"){
          logoutUser();
        }
      })

      .addCase(updateMobileSetting.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateMobileSetting.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.mobileSettingUpdate = action.payload;
        //console.log('action.payload', action.payload)
      })
      .addCase(updateMobileSetting.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        if(action.error.code === "ERR_BAD_REQUEST"){
          logoutUser();
        }
      })

      //Integration Setting
      .addCase(fetchIntegrationSetting.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchIntegrationSetting.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.integrationSettings = action.payload;
      })
      .addCase(fetchIntegrationSetting.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        if(action.error.code === "ERR_BAD_REQUEST"){
          logoutUser();
        }
      })

      .addCase(updateIntegrationSetting.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateIntegrationSetting.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.integrationSettingUpdate = action.payload;
        //console.log('action.payload', action.payload)
      })
      .addCase(updateIntegrationSetting.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        if(action.error.code === "ERR_BAD_REQUEST"){
          logoutUser();
        }
      });
  },
});

export default settingSlice.reducer;