// features/loginHistorySlice.js
// Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken, logoutUser, getValue } from '../utils/auth';

// Base URL for API requests
// const baseURL = 'https://sf-collection-api-0f31b037d478.herokuapp.com';
const baseURL = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  broadcastHistory: [],
  sendBroadcast: [],
  status: 'idle',
  error: null,
};

// Thunk for fetching broadcast history
export const fetchBroadcastHistory = createAsyncThunk('broadcast/fetchBroadcastHistory', async () => {
  let pageNum = getValue('loginhistory_cur_page');
  let size = getValue('loginhistory_page_size');
  const response = await axios.get(`${baseURL}/api/message/getBroadcastMessage`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  return response.data;
});

// Thunk for sending a broadcast
export const sendBroadcast = createAsyncThunk('broadcast/sendBroadcast', async (requestBody) => {
  const response = await axios.post(`${baseURL}/api/message/bradcast-message`, requestBody, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  return response.data;
});

// Slice for managing broadcast state
const broadcastSlice = createSlice({
  name: 'broadcast',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBroadcastHistory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBroadcastHistory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.broadcastHistory = action.payload;
      })
      .addCase(fetchBroadcastHistory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        if(action.error.code === "ERR_BAD_REQUEST"){
          logoutUser();
        }
      })
      .addCase(sendBroadcast.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sendBroadcast.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.sendBroadcast = action.payload;
      })
      .addCase(sendBroadcast.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        if(action.error.code === "ERR_BAD_REQUEST"){
          logoutUser();
        }
      });
  },
});

export default broadcastSlice.reducer;
