// Imports
import React, { useState, useEffect,useRef  } from 'react';
import { Container, Row, Col, Form, ListGroup, Card, InputGroup, FormControl,  Modal, Button ,Pagination} from 'react-bootstrap';
import { BsFilter } from 'react-icons/bs';
import { IoIosSearch } from 'react-icons/io';
import { FaEye } from 'react-icons/fa';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import axios from 'axios';
import { triggerToast } from '../utils/toast';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllContacts } from '../features/userSlice';
import { fetchAgencies, fetchBranches } from '../features/adduserSlice';
import { fetchBroadcastHistory } from '../features/broadcastSlice';
import { setValue, getToken, getValue } from '../utils/auth';

import Navbar from './Navbar';
import './Style.css';
import moment from 'moment-timezone';

// Local state
function Broadcast() {
  const isFetcingUser = useRef( false );
  const dispatch = useDispatch();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [message, setMessage] = useState("");
  // const [broadcastHistory, setBroadcastHistory] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [agencyFilter, setAgencyFilter] = useState('All');
  const [branchFilter, setBranchFilter] = useState('All');
  const { broadcastHistory, sendBroadcast} = useSelector((state) => state.broadcast);
  const { agencies, branches, status, error } = useSelector((state) => state.adduser);
  const filterBoxRef = useRef(null);
  const isFetcing = useRef( false );
  const [title, setTitle] = useState("");
  const adminSettings = useSelector((state) => state.setting.adminSettings);
  const [currentPage, setCurrentPage] = useState(1);
  const [isSending, setIsSending] = useState(false);
  const [sortKey, setSortKey] = useState('name');
  const [sortDirection, setSortDirection] = useState(true); 

  const userData = useSelector((state) => state.users.users);
  const users = userData.contacts ? userData.contacts : [];
// console.log('userData',userData)
  const base_url = process.env.REACT_APP_API_BASE_URL;

  const usersPerPage = userData.pageSize;
  const totalPages = userData.totalPages;
  // const pageIndex = (currentPage - 1) * usersPerPage;

  // Update values in local storage
  setValue('broadcast_cur_page', currentPage);
   setValue('tableDetailsFontSize', adminSettings?.properties?.tableDetailsFontSize);
   setValue('headingFontSize', adminSettings?.properties?.headingFontSize);
   setValue('subHeadingFontSize', adminSettings?.properties?.subHeadingFontSize);

// Get admin settings values for dynamic font sizes.
 const tblDetailsFontSize = getValue('tableDetailsFontSize');
 const hdngFontSize = getValue('headingFontSize');
 const subHdngFontSize = getValue('subHeadingFontSize')

 const timeZone = process.env.REACT_APP_TIMEZONE

// Format datetime for display
const formatDateTime = (date) => {
  return moment(date).format('DD MMM YYYY, h:mm:ss A');
};
  
  useEffect(() => {
    // dispatch(fetchAdminSetting());
  }, [dispatch]);

  // Fetch contacts and broadcast history on component mount
  useEffect(() => {
    if ( !isFetcingUser.current ) {
      dispatch(fetchAllContacts({ 
        sort: sortDirection, 
        sortKey: sortKey 
      }));
      
      // dispatch(fetchAdminSetting());
      isFetcingUser.current = true
    }
  }, [status,sortDirection, sortKey, dispatch,currentPage]);

  // Fetch broadcast history and filter options
  useEffect(() => {
    setValue('broadcast_cur_page', 1)
    // console.log('searchTerm', searchTerm)
    // console.log('branchFilter', branchFilter)
    // console.log('agencyFilter', agencyFilter)

    let filterObject = [];
      filterObject.activeStatus = 'active';
      filterObject.isBroadcastPage = true;
      if ( !isFetcing.current ) {
        // dispatch(fetchAllContacts(filterObject));
        dispatch(fetchBroadcastHistory());
        dispatch(fetchAgencies());
        dispatch(fetchBranches());
        isFetcing.current = true
      }
  }, [dispatch,currentPage, status]);


// Update fetching state based on status
  useEffect(() => {
    if ( status === 'succeeded' || status === 'error' ) isFetcing.current = false
}, [status]);


// Handle clicks outside the filter box to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterBoxRef.current && !filterBoxRef.current.contains(event.target)) {
        setShowFilterBox(false); 
      }
    };
  
   
    document.addEventListener("mousedown", handleClickOutside);
  
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterBoxRef]);

 // Apply filters and update contact list
  useEffect(() => {
    if (
      (agencyFilter === 'All' || agencyFilter !== undefined) ||
      (branchFilter === 'All' || branchFilter !== undefined) ||
      ((searchTerm !== undefined && (searchTerm.length > 3)))) {
      // console.log('searchTerm',searchTerm)
      // console.log('branchFilter',branchFilter)
      // console.log('agencyFilter',agencyFilter) 
      setValue('broadcast_cur_page', 1);
      let filterObject = [];
      filterObject.sort = sortDirection;
      filterObject.sortKey = sortKey;
      filterObject.isBroadcastPage = true;
      filterObject.activeStatus = 'active';
      filterObject.searchTerm = searchTerm;
      if ((agencyFilter !== 'All' && agencyFilter !== undefined)) {
        filterObject.agencyId = agencyFilter;
      }
      if ((branchFilter !== 'All' && branchFilter !== undefined)) {
        filterObject.branchId = branchFilter;
      }
      dispatch(fetchAllContacts(filterObject));
    }
  }, [dispatch, searchTerm, branchFilter, agencyFilter]);


  // const usersPerPage = userData.pageSize;
  // const totalPages = userData.totalPages;


// Handle user selection and update selectedUsers state
  const handleUserSelection = (userId) => {
    setSelectedUsers((prevSelectedUsers) => {
      const isSelected = prevSelectedUsers.includes(userId);
      if (isSelected) {
        const updatedSelectedUsers = prevSelectedUsers.filter((sfid) => sfid !== userId);
        setSelectAllChecked(updatedSelectedUsers.length === filteredUsers.length);
        return updatedSelectedUsers;
      } else {
        const updatedSelectedUsers = [...prevSelectedUsers, userId];
        setSelectAllChecked(updatedSelectedUsers.length === filteredUsers.length);
        return updatedSelectedUsers;
      }
    });
  };

   // Handle message input change
  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  // Handle sending message to selected users
  const handleSendMessage = async () => {
    if (isSending) return;
    setIsSending(true);
    if (selectedUsers.length > 0) {
        // const newMessages = selectedUsers.map(
      //   (userId) => {
      //     const user = users.find(u => u.sfid === userId);
      //     return Message sent to ${user.name}: ${message};
      //   }
      // );
      if (title.trim() !== "" && message.trim() !== "") {
        const sentOn = new Date().toLocaleString();
        //setBroadcastHistory([...broadcastHistory, { message, sentOn, users: selectedUsers.map(userId => users.find(u => u.sfid === userId)) }]);
      //broadcastHistory.data.push({ message, sentOn, broadcastUser: selectedUsers.map(userId => users.find(u => u.sfid === userId)) });
        const requestBody = JSON.parse('{"users":["' + selectedUsers.join('","') + '"],"message":"' + message + '","title":"' + title + '"}');
        // console.log('requestBody',requestBody);
        try {
          const response = await axios.post(`${base_url}/api/message/bradcast-message`, requestBody, {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            }
          });
  
          dispatch(fetchBroadcastHistory());
          setSelectedUsers([]);
          setMessage("");
          setTitle("");
          triggerToast("Message sent successfully", 'success');
        } catch (error) {
          triggerToast("Failed to send message", 'error');
        }
        finally {
          setIsSending(false);
        }
      } else {
        triggerToast("Please enter the Title and Message to send", 'error');
        setIsSending(false);
      }
    } else {
      triggerToast("Please select at least one user", 'error');
      setIsSending(false);
    }
  };

  
// Handle key press for sending message
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Add this line to prevent default form submission
      if (!title.trim()) {
        triggerToast("Please enter a title", 'error');
        return;
      }
      if (!message.trim()) {
        triggerToast("Please enter a message", 'error');
        return;
      }
      handleSendMessage();
    }
  };
  

// Handle select all checkbox
  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedUsers(filteredUsers.map(user => user.sfid));
    } else {
      setSelectedUsers([]);
    }
    setSelectAllChecked(isChecked);
  };


  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle agency filter change
  const handleAgencyFilterChange = (event) => {
    setAgencyFilter(event.target.value);
  };

  // Handle branch filter change
  const handleBranchFilterChange = (event) => {
    setBranchFilter(event.target.value);
  };

   // Show modal with message details
  const handleShowModal = (content) => {
    // console.log('content', content);
    setModalContent(content);
    setShowModal(true);
  };

  // Close modal
  const handleCloseModal = () => {
    setShowModal(false);
  };
 // Fetch and update broadcast history
  const filteredUsers = users;
  /*const filteredUsers = users
    .filter((user) => 
      (agencyFilter === 'All' || user.agency === agencyFilter) &&
      (branchFilter === 'All' || ((user.Banker?.BranchUnitBusinessMember?.branchUnit?.id)??2) === branchFilter) &&
      user.name.toLowerCase().includes(searchTerm.toLowerCase())
    );*/
    const paginate = (pageNumber) => {
      setCurrentPage(pageNumber);
      setValue('broadcast_cur_page', pageNumber);
    };

  // Calculate the range of users to display on the current page
    const startPage = Math.max(currentPage, 1);
    const endPage = Math.min(startPage + 2, totalPages);
    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

  return (
    <div className="home-container d-flex flex-column flex-lg-row">
      <Navbar />
      <main className="content flex-grow-1">
        <Container style={{ padding: "20px" }}>
          <h3 className="mb-3"style={{fontSize: hdngFontSize + "px"}} >Broadcast List</h3>
          <Card style={{ borderRadius: "15px", padding: "20px" }}>
            <Row>
              <Col
                md={4}
                style={{
                  maxHeight: "540px",
                  overflowY: "auto",
                  borderRadius: "15px",
                  padding: "20px",
                }}
              >
                <h5 style={{fontSize: subHdngFontSize + "px"}}>Select your broadcast list</h5>
                <Row className="mb-2">
                  <Col xs={8}>
                    <h6 className='mb-2 align-items-center'>Selected Users: {selectedUsers.length}</h6>
                  </Col>
                  <Col xs={4} className="text-end">
                    <Button
                      variant="link"
                      onClick={() => setShowFilterBox(!showFilterBox)}
                      style={{
                        textDecoration: 'none',
                        fontSize: 'small',
                        padding: 0,
                        color: 'black',
                        display: 'flex',
                        alignItems: 'center',
                        left:"20px",
                        position: 'relative', // Ensures the filter box is positioned relative to the button
                        zIndex: 2, 
                      }}
                    >
                      Filter <BsFilter className="ms-2" />
                    </Button>
                  </Col>
                </Row>
                {showFilterBox && (
                  <div 
                  ref={filterBoxRef} 
                  style={{
                    background: '#f8f9fa',
                    padding: '10px',
                    borderRadius: '5px',
                    width: '200px',
                    position: 'relative',

                    left: '60px',
                    zIndex: 1,
                  }}>
                    <Form.Group controlId="agencyFilter" className="d-flex align-items-center mb-2">
                      <Form.Label style={{ marginRight: '5px', fontSize: '14px' }}>Agency:</Form.Label>
                      <Form.Control as="select" value={agencyFilter} onChange={handleAgencyFilterChange} style={{ flex: '1', fontSize: '14px', padding: '5px' }}>
                        <option value="All">All</option>
                        {(Array.isArray(agencies) ? agencies : []).map((agency) => (
                          <option key={agency.id} value={agency.id}>{agency.name}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="branchFilter" className="d-flex align-items-center mb-2">
                      <Form.Label style={{ marginRight: '5px', fontSize: '14px' }}>Branch:</Form.Label>
                      <Form.Control as="select" value={branchFilter} onChange={handleBranchFilterChange} style={{ flex: '1', fontSize: '14px', padding: '5px' }}>
                        <option value="All">All</option>
                        {(Array.isArray(branches) ? branches : []).map((branch) => (
                          <option key={branch.id} value={branch.id}>{branch.name}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </div>
                )}

                <Row>
                  <Col xs={5} className='pt-2'>
                    <Form.Check
                      type="checkbox"
                      label="Select All"
                      checked={filteredUsers.length > 0 && selectedUsers.length === filteredUsers.length}
                      onChange={handleSelectAll}
                    />
                  </Col>
                  <Col xs={7}>
                    <Form className="d-flex search" role="search">
                      <InputGroup className="border rounded">
                        <InputGroup.Text className="bg-white border-0">
                          <IoIosSearch />
                        </InputGroup.Text>
                        <FormControl
                          type="search"
                          placeholder="Search"
                          value={searchTerm}
                          onChange={handleSearchChange}
                          aria-label="Search"
                          className="text-center border-0"
                          style={{ fontSize: '12px' }}
                        />
                      </InputGroup>
                    </Form>
                  </Col>
                </Row>
                <ListGroup variant="flush">
                  {filteredUsers.map((user) => (
                    <ListGroup.Item key={user.id} style={{ backgroundColor: 'transparent', borderBottom: '1px solid #ddd', padding: '10px 0', position: 'relative' }}>
                      <Form.Check
                        type="checkbox"
                        name="user"
                        id={`user-${user.sfid}`}
                        onChange={() => handleUserSelection(user.sfid)}
                        checked={selectedUsers.includes(user.sfid)}
                        label={
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                            <div>
                              <div style={{fontSize: tblDetailsFontSize + "px"}}>{user.name}</div>
                              <div style={{fontSize: tblDetailsFontSize + "px"}}>{user.mobilephone}</div>
                              <div style={{fontSize: tblDetailsFontSize + "px"}}>{user.Banker?.BranchUnitBusinessMember?.branchUnit?.name}</div>
                            </div>
                            <div style={{ position: 'absolute', bottom: '12px', right: '0', marginTop: "2px",fontSize: tblDetailsFontSize + "px" }}>
                              {user.contactAccount?.name}
                            </div>
                          </div>
                        }
                      />
                    </ListGroup.Item>
                  ))}
                </ListGroup>
                <Pagination className="justify-content-center mb-2  mt-2">
            <Pagination.Prev
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <FaChevronLeft className="text-dark" />
            </Pagination.Prev>

            {pageNumbers.map((page) => (
              <Pagination.Item
                key={page}
                active={page === currentPage}
                onClick={() => paginate(page)}
                className="ms-1 me-1"
              >
                {page}
              </Pagination.Item>
            ))}

            <Pagination.Next
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <FaChevronRight className="text-dark" />
            </Pagination.Next>
          </Pagination>
              </Col>
              <Col md={8} style={{ borderRadius: "15px", padding: "20px", display: 'flex', flexDirection: 'column' }}>
                <h5 style={{ textAlign: "center" , fontSize: subHdngFontSize + "px"}}>Broadcast History</h5>
                <div
                  style={{ flexGrow: 1, overflowY: "auto", marginBottom: "20px", maxHeight: "350px", }}
                >
                  {(broadcastHistory.data) && Array.isArray(broadcastHistory.data) && (broadcastHistory.data.length > 0) ? (
                    [...broadcastHistory.data].reverse().map((item, index) => (
                      <div key={index} style={{ marginBottom: '10px', display: (item.broadcastUser.length > 0) ? "" : "none" }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p style={{ textAlign: "left", margin: "5px 0" }}>
                          <strong>Title:</strong> {item.title}<br /> 
                          {`Sent on: ${formatDateTime(item.sentOn ?? item.createdAt)} Sent to (${item.broadcastUser.length}) ${item.broadcastUser.length === 1 ? 'User' : 'Users'}`}
                        </p>
                        <FaEye onClick={() => handleShowModal(item)} style={{  marginRight: '120px',color: '#007bff', cursor: 'pointer' }} />
                      </div>
                    </div>
                    ))
                  ) : (
                    <p>No messages broadcasted yet.</p>
                  )}
                </div>
                <Form>
                <InputGroup className="mb-2 ">
                <Form.Control
  type="text"
  placeholder="Title"
  value={title}
  onChange={(e) => setTitle(e.target.value)}
  onKeyPress={handleKeyPress}
  style={{
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    resize: "none",
    minHeight: "25px",
  }}
/>
  </InputGroup>

  {/* Message Input */}
  <InputGroup>
    <Form.Control
      as="textarea"
      placeholder="Type your message here..."
      value={message}
      onChange={handleMessageChange}
      onKeyPress={handleKeyPress}
      style={{
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        resize: "none",
        minHeight: "75px",
      }}
    />
    <InputGroup.Text
      onClick={handleSendMessage}
      style={{
        cursor: "pointer",
        backgroundColor: "white",
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeft: "none",
        display: 'flex',
        alignItems: 'center',
        fontWeight: "bold",
      }}
      disabled={isSending}
    >
      Send
    </InputGroup.Text>
  </InputGroup>
                </Form>
              </Col>
            </Row>
          </Card>
        </Container>

        {/* Modal for message details */}
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title style={{ textAlign: 'center', flexGrow: 1 }}>
              Message Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalContent && (
              <>
                <p><strong>Title:</strong> {modalContent.title}</p> 
                <p>
  <strong>Sent on:</strong> 
  {formatDateTime(modalContent.sentOn ?? modalContent.createdAt)}
</p>
                <p><strong>Message:</strong></p>
                <p>{modalContent.message}</p>
                <p><strong>Users:</strong></p>
                <ul style={{ overflowY: "auto",maxHeight:"100px"}}>
                  {modalContent.broadcastUser.map((user) => (
                    <li key={user?.messageContact?.id}>{user?.messageContact?.name || user.name}</li>
                  ))}
                </ul>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" style={{backgroundColor: adminSettings?.properties?.primaryButtonColor
    }} className="w-50 mt-3 d-block mx-auto" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </main>
    </div>
  );
}

export default Broadcast;
