// Imports
import React, { useEffect, useState } from 'react';
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { useDispatch, useSelector } from 'react-redux';
import { triggerToast } from '../utils/toast';
// import { fetchAdminSetting } from '../features/settingSlice';
import { setValue, getValue } from '../utils/auth';


function BulkUploadModal({ show, handleClose, handleChange,handleSubmit }) {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const adminSettings = useSelector((state) => state.setting.adminSettings);

  
  
  useEffect(() => {
    // dispatch(fetchAdminSetting());
  }, [dispatch]);


  // Get admin settings values for dynamic font sizes.
  setValue('labelFontSize', adminSettings.labelFontSize);

  setValue('headingFontSize', adminSettings?.properties?.headingFontSize);
 
 const hdngFontSize = getValue('headingFontSize');

  const lblFontSize = getValue('labelFontSize' );

 // Handle file input change
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

// Process CSV file on form submission
  const onSubmit = (e) => {
    e.preventDefault();
    if (selectedFile) {
      Papa.parse(selectedFile, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const mappedContacts = results.data.map(contact => ({
            firstname: contact.firstname,
            lastname: contact.lastname,
            mobile: contact.mobile,
            email: contact.email,
            gender: contact.gender.toLowerCase(),
            dateOfBirth: contact.dateOfBirth,
            pan_number: contact.pan_number,
            agency: contact.agency,
            branchId: contact.branchId
          }));
          handleSubmit({ contacts: mappedContacts });
        }
      });
    }else{
      triggerToast("Please upload CSV file.", 'error')
    }
  };

   // Download sample CSV template
  const handleDownloadTemplate = () => {
    const templateData = [
      ["firstname", "lastname", "mobile", "email", "gender", "dateOfBirth", "pan_number", "agency", "branchId"],
      ["John", "Doe", "1234567890", "john.doe@example.com", "male", "1990-01-01", "ABCDE1234F", "1", "1"],
      ["Smith", "Doe", "1976544777", "smith.doe@example.com", "female", "2090-01-01", "ABCDE87234F", "2", "1"],
      // Add more sample data if needed
    ];
    const csv = Papa.unparse(templateData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'user-template-sample.csv');
  };
  return (
    <Modal show={show} onHide={handleClose} centered dialogClassName="modal-custom">
      <Modal.Header closeButton style={{ display: 'flex', justifyContent: 'center' }}>
        {/* Modal title with dynamic font size */}
        <Modal.Title style={{ flex: 1, textAlign: 'center',fontSize: hdngFontSize + "px" }}>Bulk Upload</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} xs={9} className="mb-3">
               {/* File input for CSV upload */}
              <Form.Label style={{ fontSize: lblFontSize + "px"}}>Upload User CSV</Form.Label>
              <Form.Control type="file" accept=".csv" onChange={handleFileChange} />
            </Form.Group>
            

          
           <Form.Group as={Col} className="mb-3">
            {/* Submit button */}
            <Button
              variant="primary"
              type="submit"
              style={{  marginTop: '30px' ,backgroundColor: adminSettings?.properties?.primaryButtonColor }}
              className="text-center "
             
            >Upload</Button>
          </Form.Group>
          </Row>
         
          <Form.Group as={Col} className="mb-3" style={{ display: 'flex', justifyContent: 'center' }}>
              <Form.Label style={{ fontSize: lblFontSize + "px"}}>Download <Link onClick={handleDownloadTemplate} className="text-decoration-none">
                <i>Sample Template</i>
              </Link></Form.Label>
            </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default BulkUploadModal;
