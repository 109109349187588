// Imports
import React, { useEffect,useState,useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAdminSettingsHistory } from '../features/adminHistorySlice';
import Navbar from './Navbar';
import { Container, Row, Col, ListGroup,Pagination,Spinner } from 'react-bootstrap';
// import { fetchAdminSetting } from '../features/settingSlice';
import { setValue, getValue } from '../utils/auth'; // getToken,
import { FaChevronLeft, FaChevronRight} from 'react-icons/fa';
import moment from 'moment-timezone';


const AdminHistory = () => {
  const isFetcingUser = useRef( false );
  const dispatch = useDispatch();
  const { adminSettingsHistory, status, error } = useSelector((state) => state.adminHistory);
  // const adminSettings = useSelector((state) => state.setting.adminSettings);


  const usersPerPage = 10; 
  const totalPages = adminSettingsHistory?.totalPages;
  const settingsHistory = adminSettingsHistory.adminSettingsHistory;
  setValue('adminhistory_page_size', usersPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  // const pageIndex = (currentPage-1)*usersPerPage;
  setValue('adminhistory_cur_page', currentPage);
// console.log('adminSettingsHistory',status, error,settingsHistory)

  useEffect(() => {
    // dispatch(fetchAdminSetting());
  }, [dispatch]);

  // Get admin settings values for dynamic font sizes.
  const hdngFontSize = getValue('headingFontSize');
  const subHdngFontSize = getValue('subHeadingFontSize')

  
   // Get timezone from environment variables
  const timeZone = process.env.REACT_APP_TIMEZONE 

// Format the date-time using Moment.js
  const formatDateTime = (date) => {
    return moment(date).format('DD MMM YYYY, h:mm:ss A');
  };

  // Fetch admin settings history
  useEffect(() => {
    if ( !isFetcingUser.current ) {
      dispatch(fetchAdminSettingsHistory());
      isFetcingUser.current = true
    }
  }, [dispatch,status,currentPage]);


  useEffect(() => {
    if ( status === 'succeeded' || status === 'error' ) isFetcingUser.current = false
}, [status]);

    // Handle pagination
    const paginate = (pageNumber) => {
      // console.log("pageNum", currentPage, pageNumber)
      setCurrentPage(pageNumber);
      setValue('adminhistory_cur_page', pageNumber);
    }
    
    const startPage = Math.max(currentPage, 1);
    const endPage = Math.min(startPage + 2, totalPages);
    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
  

     // Filter the settings history to show only changed settings
    let filterItems = (settingsHistory) => {
      let new_array = [];
      // let new_array = settingsHistory;
      (settingsHistory || []).map((item, index) => {
        
        // console.log('item',item.currentsetting)
        // item.previoussetting.map((prevItem, prevIndex) => {
          let previoussetting = item.previoussetting;
          let currentsetting = item.currentsetting;
          
           // Initialize empty structure for current and previous settings
          if(!new_array[index]) new_array[index] = [];
          if(!new_array[index]['currentsetting']) new_array[index]['currentsetting'] = [];
          if(!new_array[index]['previoussetting']) new_array[index]['previoussetting'] = [];
          if(!new_array[index]['currentsetting']['properties']) new_array[index]['currentsetting']['properties'] = [];
          if(!new_array[index]['previoussetting']['properties']) new_array[index]['previoussetting']['properties'] = [];
          
          // Compare current and previous settings
          for(var key in currentsetting){
            let settingVal = '';
            if(key==='properties'){
              for(var propKey in currentsetting.properties){
                let propSettingVal = currentsetting.properties[propKey];
                // console.log('propKey',currentsetting.properties[propKey])
                if(previoussetting.properties[propKey] && (propSettingVal !== previoussetting.properties[propKey]) ){
                  // console.log('propKey',propKey, propSettingVal)
                  new_array[index]['previoussetting']['properties'][propKey]=previoussetting.properties[propKey];
                  new_array[index]['currentsetting']['properties'][propKey]=propSettingVal;
                }
              }
            }else{
              if(key !=='default_values'){
                settingVal = currentsetting[key];
                if(previoussetting[key] && (settingVal !== previoussetting[key]) ){
                  new_array[index]['previoussetting'][key]=previoussetting[key];
                  new_array[index]['currentsetting'][key]=settingVal;
                  
                }
              }
            }
            
          }
          if( (Object.keys(new_array[index]['previoussetting']).length < 2) || (Object.keys(new_array[index]['previoussetting']['properties']).length <1) ){
            console.log('previoussetting', Object.keys(new_array[index]['previoussetting']));
            delete new_array[index]['currentsetting'];
            delete new_array[index]['previoussetting'];
          }
      });
      return new_array;
  };
  // console.log('adminSettingsHistory',adminSettingsHistory[0])
  let filteredSettingHistory = [];
  
  // filteredSettingHistory = filterItems(settingsHistory);
  // console.log('filteredSettingHistory',filteredSettingHistory)
  filteredSettingHistory = settingsHistory;

  return (
    <div className="home-container d-flex flex-column flex-lg-row">
      <Navbar />
      <main className="content flex-grow-1">
        <Container className="admin-history mt-3">
        <div className="mt-4 mb-4">
      <h2  style={{fontSize: hdngFontSize + "px"}}>Admin Settings History</h2>
      <p>Settings / Admin Panel Settings / History</p></div>
      {status === 'loading' ? (
            <div className="text-center mt-5">
              <Spinner animation="border" variant="primary" />
              <p>Loading...</p>
            </div>
          ) : status === 'failed' ? (
            <div className="text-center mt-5 text-danger">
              <p>{error}</p>
            </div>
          ) : (
            
          <Row>
            <Col md={6}>
              <h2 style={{fontSize: subHdngFontSize + "px"}}>Previous Settings</h2>
              {/* Display Previous Settings */}
              <ListGroup>
              {(filteredSettingHistory?.length>0)?(
                  (filteredSettingHistory || []).map((item, index) => (
                  
                  <ListGroup.Item key={index}>
                    {item.previoussetting?.updatedAt ? (<p><strong>Date:</strong> {formatDateTime(item.previoussetting?.updatedAt)|| 'N/A'}</p>):''}
                    {item.previoussetting?.sideMenuColor ? (
                      <p className={(item.previoussetting?.sideMenuColor !== item.currentsetting?.sideMenuColor)?'yelloBg':''}><strong>Side Menu Color:</strong> {item.previoussetting?.sideMenuColor}</p>
                    ):''}
                     {item.previoussetting?.properties?.headingFontSize ? (
                      <p className={(item.previoussetting?.properties?.headingFontSize !== item.currentsetting?.properties?.headingFontSize)?'yelloBg':''}><strong>Heading Font Size:</strong> {item.previoussetting?.properties?.headingFontSize}</p>
                    ):''}
                     {item.previoussetting?.properties?.subHeadingFontSize ? (
                      <p className={(item.previoussetting?.properties?.subHeadingFontSize !== item.currentsetting?.properties?.subHeadingFontSize)?'yelloBg':''}><strong>Sub-Heading Font Size:</strong> {item.previoussetting?.properties?.subHeadingFontSize}</p>
                    ):''}
                     {item.previoussetting?.properties?.primaryButtonColor ? (
                      <p className={(item.previoussetting?.properties?.primaryButtonColor !== item.currentsetting?.properties?.primaryButtonColor)?'yelloBg':''}><strong>Primary Button Color:</strong> {item.previoussetting?.properties?.primaryButtonColor}</p>
                    ):''}
                     {item.previoussetting?.properties?.secondaryButtonColor ? (
                      <p className={(item.previoussetting?.properties?.secondaryButtonColor !== item.currentsetting?.properties?.secondaryButtonColor)?'yelloBg':''}><strong>Secondary Button Color:</strong> {item.previoussetting?.properties?.secondaryButtonColor}</p>
                    ):''}
                    {item.previoussetting?.iconColor ? (
                      <p className={(item.previoussetting?.iconColor !== item.currentsetting?.iconColor)?'yelloBg':''}><strong>Icon Color:</strong> {item.previoussetting?.iconColor}</p>
                    ):''}
                    {item.previoussetting?.iconFontSize ? (
                      <p className={(item.previoussetting?.iconFontSize !== item.currentsetting?.iconFontSize)?'yelloBg':''}><strong>Icon Font Size:</strong> {item.previoussetting?.iconFontSize}</p>
                    ):''}
                     {item.previoussetting?.properties?.tableHeadingFontSize ? (
                      <p className={(item.previoussetting?.properties?.tableHeadingFontSize !== item.currentsetting?.properties?.tableHeadingFontSize)?'yelloBg':''}><strong>Table Heading Font Size:</strong> {item.previoussetting?.properties?.tableHeadingFontSize}</p>
                    ):''}
                     {item.previoussetting?.properties?.tableDetailsFontSize ? (
                      <p className={(item.previoussetting?.properties?.tableDetailsFontSize !== item.currentsetting?.properties?.tableDetailsFontSize)?'yelloBg':''}><strong>User Details Font Size:</strong> {item.previoussetting?.properties?.tableDetailsFontSize}</p>
                    ):''}
                     {item.previoussetting?.labelFontSize ? (
                      <p className={(item.previoussetting?.labelFontSize !== item.currentsetting?.labelFontSize)?'yelloBg':''}><strong>Label Font Size:</strong> {item.previoussetting?.labelFontSize}</p>
                    ):''}
                     {item.previoussetting?.tokenExpirationHour ? (
                      <p className={(item.previoussetting?.tokenExpirationHour !== item.currentsetting?.tokenExpirationHour)?'yelloBg':''}><strong>Token Expiration Hour:</strong> {item.previoussetting?.tokenExpirationHour}</p>
                    ):''}
                    
                    {/* {item.previoussetting?.properties?.headingFontSize ? (<p><strong>Heading Font Size:</strong> {item.previoussetting?.properties?.headingFontSize || 'N/A'}px</p>):''}
                    {item.previoussetting?.properties?.subHeadingFontSize ? (<p><strong>Sub-Heading Font Size:</strong> {item.previoussetting?.properties?.subHeadingFontSize || 'N/A'}px</p>):''}
                    {item.previoussetting?.properties?.primaryButtonColor ? (<p><strong>Primary Button Color:</strong> {item.previoussetting?.properties?.primaryButtonColor || 'N/A'}</p>):''}
                    {item.previoussetting?.properties?.secondaryButtonColor ? (<p><strong>Secondary Button Color:</strong> {item.previoussetting?.properties?.secondaryButtonColor || 'N/A'}</p>):''}
                    {item.previoussetting?.iconColor ? (<p><strong>Icon Color:</strong> {item.previoussetting?.iconColor || 'N/A'}</p>):''}
                    {item.previoussetting?.iconFontSize ? (<p><strong>Icon Font Size:</strong> {item.previoussetting?.iconFontSize || 'N/A'}px</p>):''}
                    {item.previoussetting?.properties?.tableHeadingFontSize ? (<p><strong>Table Heading Font Size:</strong> {item.previoussetting?.properties?.tableHeadingFontSize || 'N/A'}px</p>):''}
                    {item.previoussetting?.properties?.tableDetailsFontSize ? (<p><strong>User Details Font Size:</strong> {item.previoussetting?.properties?.tableDetailsFontSize || 'N/A'}px</p>):''}
                    {item.previoussetting?.labelFontSize ? (<p><strong>Label Font Size:</strong> {item.previoussetting?.labelFontSize || 'N/A'}px</p>):''} */}
                  </ListGroup.Item>
                
              ))):(
                <ListGroup.Item>
                  <p><strong>No settings found</strong> </p>
                  </ListGroup.Item>
              )}
              </ListGroup>
            </Col>

            <Col md={6}>
              <h2 style={{fontSize: subHdngFontSize + "px"}}>Current Settings</h2>
              <ListGroup>
              {(filteredSettingHistory?.length>0)?(
                  (filteredSettingHistory || []).map((item, index) => (
                
                  <ListGroup.Item key={index}>
                    {item.currentsetting?.updatedAt ? (<p><strong>Date:</strong> {formatDateTime(item.currentsetting?.updatedAt) || 'N/A'}</p>):''}
                    {item.currentsetting?.sideMenuColor ? (
                      <p className={(item.previoussetting?.sideMenuColor !== item.currentsetting?.sideMenuColor)?'yelloBg':''}><strong>Side Menu Color:</strong> {item.currentsetting?.sideMenuColor || 'N/A'}</p>
                      ):''}
                      {item.currentsetting?.properties?.headingFontSize ? (
                      <p className={(item.previoussetting?.properties?.headingFontSize !== item.currentsetting?.properties?.headingFontSize)?'yelloBg':''}><strong>Heading Font Size:</strong> {item.currentsetting?.properties?.headingFontSize || 'N/A'}</p>
                      ):''}
                       {item.currentsetting?.properties?.subHeadingFontSize ? (
                      <p className={(item.previoussetting?.properties?.subHeadingFontSize !== item.currentsetting?.properties?.subHeadingFontSize)?'yelloBg':''}><strong>Sub-Heading Font Size:</strong> {item.currentsetting?.properties?.subHeadingFontSize || 'N/A'}</p>
                      ):''}
                      {item.currentsetting?.properties?.primaryButtonColor ? (
                      <p className={(item.previoussetting?.properties?.primaryButtonColor !== item.currentsetting?.properties?.primaryButtonColor)?'yelloBg':''}><strong>Primary Button Color:</strong> {item.currentsetting?.properties?.primaryButtonColor || 'N/A'}</p>
                      ):''}
                       {item.currentsetting?.properties?.secondaryButtonColor ? (
                      <p className={(item.previoussetting?.properties?.secondaryButtonColor !== item.currentsetting?.properties?.secondaryButtonColor)?'yelloBg':''}><strong>Secondary Button Color:</strong> {item.currentsetting?.properties?.secondaryButtonColor || 'N/A'}</p>
                      ):''}
                       {item.currentsetting?.iconColor ? (
                      <p className={(item.previoussetting?.iconColor !== item.currentsetting?.iconColor)?'yelloBg':''}><strong>Icon Color:</strong> {item.currentsetting?.iconColor || 'N/A'}</p>
                      ):''}
                      {item.currentsetting?.iconFontSize ? (
                      <p className={(item.previoussetting?.iconFontSize !== item.currentsetting?.iconFontSize)?'yelloBg':''}><strong>Icon Font Size:</strong> {item.currentsetting?.iconFontSize || 'N/A'}</p>
                      ):''}
                      {item.currentsetting?.properties?.tableHeadingFontSize ? (
                      <p className={(item.previoussetting?.properties?.tableHeadingFontSize !== item.currentsetting?.properties?.tableHeadingFontSize)?'yelloBg':''}><strong>Table Heading Font Size:</strong> {item.currentsetting?.properties?.tableHeadingFontSize || 'N/A'}</p>
                      ):''}
                      {item.currentsetting?.properties?.tableDetailsFontSize  ? (
                      <p className={(item.previoussetting?.properties?.tableDetailsFontSize  !== item.currentsetting?.properties?.tableDetailsFontSize )?'yelloBg':''}><strong>User Details Font Size:</strong> {item.currentsetting?.properties?.tableDetailsFontSize  || 'N/A'}</p>
                      ):''}
                       {item.currentsetting?.labelFontSize ? (
                      <p className={(item.previoussetting?.labelFontSize !== item.currentsetting?.labelFontSize)?'yelloBg':''}><strong>Label Font Size:</strong> {item.currentsetting?.labelFontSize || 'N/A'}</p>
                      ):''}
                      {item.currentsetting?.tokenExpirationHour ? (
                      <p className={(item.previoussetting?.tokenExpirationHour !== item.currentsetting?.tokenExpirationHour)?'yelloBg':''}><strong>Token Expiration Hour:</strong> {item.currentsetting?.tokenExpirationHour || 'N/A'}</p>
                      ):''}
                    {/* {item.currentsetting?.properties?.headingFontSize ? (<p><strong>Heading Font Size:</strong> {item.currentsetting?.properties?.headingFontSize || 'N/A'}px</p>):''}
                    {item.currentsetting?.properties?.subHeadingFontSize ? (<p><strong>Sub-Heading Font Size:</strong> {item.currentsetting?.properties?.subHeadingFontSize || 'N/A'}px</p>):''}
                    {item.currentsetting?.properties?.primaryButtonColor ? (<p><strong>Primary Button Color:</strong> {item.currentsetting?.properties?.primaryButtonColor || 'N/A'}</p>):''}
                    {item.currentsetting?.properties?.secondaryButtonColor ? (<p><strong>Secondary Button Color:</strong> {item.currentsetting?.properties?.secondaryButtonColor || 'N/A'}</p>):''}
                    {item.currentsetting?.iconColor ? (<p><strong>Icon Color:</strong> {item.currentsetting?.iconColor || 'N/A'}</p>):''}
                    {item.currentsetting?.iconFontSize ? (<p><strong>Icon Font Size:</strong> {item.currentsetting?.iconFontSize || 'N/A'}px</p>):''}
                    {item.currentsetting?.properties?.tableHeadingFontSize ? (<p><strong>Table Heading Font Size:</strong> {item.currentsetting?.properties?.tableHeadingFontSize || 'N/A'}px</p>):''}
                    {item.currentsetting?.properties?.tableDetailsFontSize ? (<p><strong>User Details Font Size:</strong> {item.currentsetting?.properties?.tableDetailsFontSize || 'N/A'}px</p>):''}
                    {item.currentsetting?.labelFontSize ? (<p><strong>Label Font Size:</strong> {item.currentsetting?.labelFontSize || 'N/A'}px</p>):''} */}
                  </ListGroup.Item>
                ))):(
                  <ListGroup.Item>
                    <p><strong>No settings found</strong> </p>
                    </ListGroup.Item>
                )}
              </ListGroup>
            </Col>
          </Row>
           )}
          <Pagination className="justify-content-end mb-3 me-2">
                <Pagination.Prev
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <FaChevronLeft className="text-dark" />
                </Pagination.Prev>

                {pageNumbers.map((page) => (
                  <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => paginate(page)}
                    className="ms-1 me-1"
                  >
                    {page}
                  </Pagination.Item>
                ))}

                <Pagination.Next
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  <FaChevronRight className="text-dark" />
                </Pagination.Next>
              </Pagination>
        </Container>
      </main>
    </div>
  );
};

export default AdminHistory;
