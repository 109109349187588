import React from 'react';
import ReactDOM from 'react-dom/client';
// import { GoogleOAuthProvider } from '@react-oauth/google';
import { Auth0Provider } from '@auth0/auth0-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';

// const redirectURL = "https://sf-collection-admin-74024790cf5d.herokuapp.com";
// const redirectURL = "http://localhost:3000";
const redirectURL = window.location.origin;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  /*<GoogleOAuthProvider clientId='768074121811-qec7h8jih51qpl7cmsms0hujbt17l2s1.apps.googleusercontent.com'>
    <React.StrictMode>
      <Provider store={store}>
          <App />
        <ToastContainer />
      </Provider>
    </React.StrictMode>
  </GoogleOAuthProvider>*/
  <Auth0Provider
    domain="dev-juhgrj75ogys3t6z.us.auth0.com"
    clientId="mzIA21b9nzwvfwdZgG5auLP0IpTSq83O"
    authorizationParams={{
      redirect_uri: redirectURL
    }}
  >
    <React.StrictMode>
      <Provider store={store}>
          <App />
        <ToastContainer />
      </Provider>
    </React.StrictMode>
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
