//imports
import React, { useEffect, useState, forwardRef } from 'react';
import { Modal, Form, Row, Col, Button, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FaCalendarAlt } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import { setToken, getToken } from '../utils/auth'
// import { fetchAdminSetting } from '../features/settingSlice';


// Custom input for DatePicker with an icon
const CustomDateInput = forwardRef(({ value, onClick, placeholder }, ref) => (
    <InputGroup>
      <Form.Control
        value={value}
        onClick={onClick}
        ref={ref}
        placeholder={placeholder}
      />
      <InputGroup.Text onClick={onClick}>
        <FaCalendarAlt />
      </InputGroup.Text>
    </InputGroup>
  ));

function DeactivateModal({ show, handleDeactivateClose, handleDeactivateChange, handleDeactivateSubmit, setDeactivateDate, deactivateDate }) {
  const dispatch = useDispatch();
  //const adminSettings = useSelector((state) => state.setting.adminSettings);
  const { adminSettings, status, error } = useSelector((state) => state.setting);
  const [deactivateOpt, setDeactivateOpt] = useState('deactivate');

  // Fetch admin settings
  useEffect(() => {
    if (status === 'idle') {
    // dispatch(fetchAdminSetting());
    }
  }, [status, dispatch]);
  
 // Function to handle changing of deactivate options
  const handleOption = (e, opt) => {
    //const opt = e.target.checked;
    setDeactivateOpt(opt);

    // console.log()


    // Conditionally handle the deactivate options based on selected value
    if(opt === 'deactivateWipe')
        handleDeactivateChange('', true, opt);
    else if(opt === 'temporaryInactive'){
        // console.log('deactivateDate',deactivateDate);
        handleDeactivateChange(deactivateDate, false, opt);        
    }else
        handleDeactivateChange('', false,opt);
    
  };

  // Handle form submission for deactivation
  const onSubmit = (event) => {
    // console.log('deactivateOpt',deactivateOpt, deactivateDate)
    event.preventDefault();
    
        
    handleDeactivateSubmit();
    handleDeactivateClose()
    setDeactivateOpt('deactivate');
  };

  return (
    <Modal show={show} onHide={handleDeactivateClose} centered dialogClassName="modal-md">
      <Modal.Header closeButton style={{ display: 'flex', justifyContent: 'center' }}>
        <Modal.Title style={{ flex: 1, textAlign: 'center' }}>Deactive User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Check // prettier-ignore
                    type="radio"
                    id={`default-radio`}
                    label={`Deactivate`}
                    name="deactivate"
                    onChange={(e) => handleOption(e, 'deactivate')}
                    checked={deactivateOpt === 'deactivate'} 
                />
            </Form.Group>
        </Row>
        <Row className="mb-3">
        <Form.Group as={Col} >
              <Form.Check // prettier-ignore
                    type="radio"
                    id={`default-radio`}
                    label={`Deactivate and Wipe Data`}
                    name="deactivate"
                    onChange={(e) => handleOption(e, 'deactivateWipe')}
                    checked={deactivateOpt === 'deactivateWipe'}
                />
            </Form.Group>
        </Row>
        <Row className="mb-3">
        <Form.Group as={Col} >
            
              <Form.Check // prettier-ignore
                    type="radio"
                    id={`default-radio`}
                    label={`Deactivate Till`}
                    name="deactivate"
                    onChange={(e) => handleOption(e, 'temporaryInactive')}
                    checked={deactivateOpt === 'temporaryInactive'}
                    style={{
                        display: 'inline-block',
                        marginRight: 10
                      }}
                />
                
                <DatePicker
                  selected={deactivateDate}
                  onChange={(date) => setDeactivateDate(date)}
                  customInput={<CustomDateInput placeholder="From Date" />}
                  minDate={new Date()} 
                />
              
            </Form.Group>
        </Row>
          <Button
            variant="primary"
            type="submit"
            style={{ width: '25%', marginTop: '20px',backgroundColor: adminSettings?.properties?.primaryButtonColor  }}
            className="text-center d-block mx-auto"
          >
            Apply
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default DeactivateModal;
