// Imports
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from './Navbar';
import { Container, Tabs, Tab, Form, Row, Col, Button } from 'react-bootstrap';
import { fetchIntegrationSetting, updateIntegrationSetting } from '../features/settingSlice';
import { triggerToast } from '../utils/toast';
import { fetchAdminSetting } from '../features/settingSlice';
import {  getValue } from '../utils/auth';
import './Style.css';

// IntegrationSetting component
function IntegrationSetting() {
  const dispatch = useDispatch();
  const integrationSettings = useSelector((state) => state.setting.integrationSettings.settings);
  const status = useSelector((state) => state.setting.status);
  const error = useSelector((state) => state.setting.error);
  const [paymentSettings, setPaymentSetting] = useState({});
  const [smsSettings, setSMSSetting] = useState({});
  const [emailSettings, setEmailSetting] = useState({});
  const [mapSettings, setMapSetting] = useState({});
  const [type, setType] = useState('payment');
  const adminSettings = useSelector((state) => state.setting.adminSettings);


// Fetch admin settings
  useEffect(() => {
    dispatch(fetchAdminSetting());
  }, [dispatch]);

  // setValue('labelFontSize', adminSettings.labelFontSize);
  
  // setValue('headingFontSize', adminSettings?.properties?.headingFontSize);
  // setValue('subHeadingFontSize', adminSettings?.properties?.subHeadingFontSize);

 // Get admin settings values for dynamic font sizes.
  const lblFontSize = getValue('labelFontSize');
  
  const hdngFontSize = getValue('headingFontSize');
  const subHdngFontSize = getValue('subHeadingFontSize')

   // Fetch integration settings
  useEffect(() => {
    dispatch(fetchIntegrationSetting('payment'));
  }, [dispatch]);

  // Map integration settings to state
  useEffect(() => {
    if (Array.isArray(integrationSettings)) {
      const settingsMap = integrationSettings.reduce((acc, setting) => {
        acc[setting.key] = setting.value;
        return acc;
      }, {});
      setPaymentSetting(settingsMap['payment'] || {});
      setSMSSetting(settingsMap['sms'] || {});
      setEmailSetting(settingsMap['email'] || {});
      setMapSetting(settingsMap['map'] || {});
    }
  }, [integrationSettings]);

   // Handle tab selection
  const handleSelect = (e) => {
    setType(e);
    //console.log('handleSelect',e)
  };

    // Handle changes in payment settings
  const handlePaymentChange = (e) => {
    const { name, value } = e.target;
    setPaymentSetting({ ...paymentSettings, [name]: value });
  };

  // Handle changes in SMS settings
  const handleSMSChange = (e) => {
    const { name, value } = e.target;
    setSMSSetting({ ...smsSettings, [name]: value });
  };

   // Handle changes in email settings
  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    setEmailSetting({ ...emailSettings, [name]: value });
  };

   // Handle changes in map settings
  const handleMapChange = (e) => {
    const { name, value } = e.target;
    setMapSetting({ ...mapSettings, [name]: value });
  };

   // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    //const formData = new FormData(event.target);

    let requestBody = [];


    if (Object.keys(paymentSettings).length) {
      //console.log('paymentSettings',paymentSettings)
      requestBody.push({
        "type": "payment",
        "value": paymentSettings
      });
    }
    if (Object.keys(smsSettings).length) {
      // console.log('smsSettings',smsSettings)
      requestBody.push({
        "type": "sms",
        "value": smsSettings
      });
    }
    if (Object.keys(emailSettings).length) {
      // console.log('emailSettings',emailSettings)
      requestBody.push({
        "type": "email",
        "value": emailSettings
      });
    }
    if (Object.keys(mapSettings).length) {
      // console.log('mapSettings',mapSettings)
      requestBody.push({
        "type": "map",
        "value": mapSettings
      });
    }



    console.log('requestBody', requestBody)
    dispatch(updateIntegrationSetting(requestBody));
    triggerToast("Details saved successfully", 'success');
  };

  // let paymentVals = null;
  // let smsVals = null;
  // let emailVals = null;
  // let mapVals = null;

  // console.log(integrationSettings)

  // = (integrationSettings.key == 'payment')?integrationSettings.value:null;
  //console.log('paymentVals',paymentVals)
  return (
    <div className="home-container d-flex flex-column flex-lg-row">
      <Navbar />
      <main className="content flex-grow-1">
        <Container>
          <h3 className="mt-4" style={{ fontSize: hdngFontSize + "px" }}>Integration Settings</h3>
          <p className="mb-3" style={{ fontSize: subHdngFontSize + "px" }}>Settings / Integration Settings</p>
          <Tabs defaultActiveKey="payment" id="integration-tabs" style={{ color: 'white' }} onSelect={(e) => handleSelect(e)}>
            <Tab eventKey="payment" title={<span style={{ color: 'grey' }}>Payment</span>}>
              <div style={{ backgroundColor: 'white', borderRadius: '5px', padding: '20px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                <p>Please enter your details for payment gateway integration</p>
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="gatewayurl">
                      <Form.Label style={{ fontSize: lblFontSize + "px" }}>Gateway URL</Form.Label>
                      <Form.Control type="text" name="gatewayurl" defaultValue={paymentSettings?.gateway_url || ''} onChange={handlePaymentChange} />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="clientid">
                      <Form.Label style={{ fontSize: lblFontSize + "px" }} >Client ID</Form.Label>
                      <Form.Control type="text" name="clientid" defaultValue={paymentSettings?.client_id || ''} onChange={handlePaymentChange} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="clientsecret">
                      <Form.Label style={{ fontSize: lblFontSize + "px" }}>Client Secret</Form.Label>
                      <Form.Control type="text" name="clientsecret" defaultValue={paymentSettings?.client_secret || ''} onChange={handlePaymentChange} />
                    </Form.Group>
                  </Row>
                  <div className="d-flex justify-content-center">
                    <Button variant="primary" style={{ backgroundColor: adminSettings?.properties?.primaryButtonColor }} type="submit">Save</Button>
                  </div>
                </Form>
              </div>
            </Tab>
            <Tab eventKey="sms" title={<span style={{ color: 'grey' }}>SMS</span>}>
              <div style={{ backgroundColor: 'white', borderRadius: '5px', padding: '20px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                <p>Please enter your details for SMS gateway integration:</p>
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="smsurl">
                      <Form.Label style={{ fontSize: lblFontSize + "px" }}>SMS URL</Form.Label>
                      <Form.Control type="text" name="smsurl" defaultValue={smsSettings?.smsurl || ''} onChange={handleSMSChange} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="smsclientid">
                      <Form.Label style={{ fontSize: lblFontSize + "px" }}>Client ID</Form.Label>
                      <Form.Control type="text" name="smsclientid" defaultValue={smsSettings?.smsclientid || ''} onChange={handleSMSChange} />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="smsclientsecret">
                      <Form.Label style={{ fontSize: lblFontSize + "px" }}>Client Secret</Form.Label>
                      <Form.Control type="text" name="smsclientsecret" defaultValue={smsSettings?.smsclientsecret || ''} onChange={handleSMSChange} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="smstemplateid">
                      <Form.Label style={{ fontSize: lblFontSize + "px" }} >SMS Template ID</Form.Label>
                      <Form.Control type="text" name="smstemplateid" defaultValue={smsSettings?.smstemplateid || ''} onChange={handleSMSChange} />
                    </Form.Group>
                  </Row>
                  <div className="d-flex justify-content-center">
                    <Button variant="primary" type="submit" style={{ backgroundColor: adminSettings?.properties?.primaryButtonColor }} >Save</Button>
                  </div>
                </Form>
              </div>
            </Tab>
            <Tab eventKey="email" title={<span style={{ color: 'grey' }}>Email</span>}>
              <div style={{ backgroundColor: 'white', borderRadius: '5px', padding: '20px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                <p>Please enter your details for email gateway integration:</p>
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="smtphost">
                      <Form.Label style={{ fontSize: lblFontSize + "px" }} >SMTP Host</Form.Label>
                      <Form.Control type="text" name="smtphost" defaultValue={emailSettings?.smtpHost || ''} onChange={handleEmailChange} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="smtpport">
                      <Form.Label style={{ fontSize: lblFontSize + "px" }} >SMTP Port</Form.Label>
                      <Form.Control type="text" name="smtpport" defaultValue={emailSettings?.smtpPort || ''} onChange={handleEmailChange} />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="smtpemailid">
                      <Form.Label style={{ fontSize: lblFontSize + "px" }}>SMTP Email ID</Form.Label>
                      <Form.Control type="email" name="smtpemailid" defaultValue={emailSettings?.smtpEmailId || ''} onChange={handleEmailChange} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="smtppassword">
                      <Form.Label style={{ fontSize: lblFontSize + "px" }}>SMTP Password</Form.Label>
                      <Form.Control type="text" name="smtppassword" defaultValue={emailSettings?.smtpPassword || ''} onChange={handleEmailChange} />
                    </Form.Group>
                  </Row>
                  <div className="d-flex justify-content-center">
                    <Button variant="primary" type="submit" style={{ backgroundColor: adminSettings?.properties?.primaryButtonColor }} >Save</Button>
                  </div>
                </Form>
              </div>
            </Tab>
            <Tab eventKey="googleMap" title={<span style={{ color: 'grey' }}>Google Map</span>}>
              <div style={{ backgroundColor: 'white', borderRadius: '5px', padding: '20px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                <p>Please enter your details for Google Map gateway integration</p>
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="ampurl">
                      <Form.Label style={{ fontSize: lblFontSize + "px" }}>Map URL</Form.Label>
                      <Form.Control type="text" name="ampurl" defaultValue={mapSettings?.mapUrl || ''} onChange={handleMapChange} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="apikey">
                      <Form.Label style={{ fontSize: lblFontSize + "px" }}>API Key</Form.Label>
                      <Form.Control type="text" name="apikey" defaultValue={mapSettings?.apiKey || ''} onChange={handleMapChange} />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="googleMapProjectId">
                      <Form.Label style={{ fontSize: lblFontSize + "px" }}>Project ID</Form.Label>
                      <Form.Control type="text" name="googleMapProjectId" defaultValue={mapSettings?.googleMapProjectId || ''} onChange={handleMapChange} />
                    </Form.Group>
                    <Form.Group as={Col} controlId="googleMapApiEndpoint">
                      <Form.Label style={{ fontSize: lblFontSize + "px" }}>API Endpoint</Form.Label>
                      <Form.Control type="text" name="googleMapApiEndpoint" defaultValue={mapSettings?.googleMapApiEndpoint || ''} onChange={handleMapChange} />
                    </Form.Group>
                  </Row>
                  <div className="d-flex justify-content-center">
                    <Button variant="primary" type="submit" style={{ backgroundColor: adminSettings?.properties?.primaryButtonColor }} > Save</Button>
                  </div>
                </Form>
              </div>
            </Tab>
          </Tabs>
          {status === 'loading' && <p>Loading...</p>}
          {status === 'failed' && <p className="text-danger">{error}</p>}
        </Container>
      </main>
    </div>
  );
}

export default IntegrationSetting;
