// Imports
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaUser, FaCog, FaAngleDown, FaLock } from 'react-icons/fa';
import { LiaBroadcastTowerSolid } from "react-icons/lia";
import { IoIosPower, IoMdSync } from 'react-icons/io';
import { MdDevices } from 'react-icons/md';
import { Modal, Button} from 'react-bootstrap';
import { triggerManualSync, fetchManualSyncRecords } from '../features/manualSyncSlice';
import { setValue, getToken ,getValue, logoutUser} from '../utils/auth';
import { fetchAdminSetting } from '../features/settingSlice';
import { triggerToast } from '../utils/toast';
import { adminLogout, adminVerifyToken } from '../features/authSlice';
import { FaRecycle } from "react-icons/fa";
import { GrLogin } from "react-icons/gr";
import { useAuth0 } from "@auth0/auth0-react";
import './Style.css';
import moment from 'moment-timezone';

// Navbar Component
function Navbar() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [lastUpdateDate, setLastUpdateDate] = useState(null);
  const [rotating, setRotating] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuth0();
  const { adminSettings, status, error } = useSelector((state) => state.setting);
  const manualSyncRecords = useSelector((state) => state.manualSync.manualSyncRecords) || [];
  const dispatch = useDispatch();
  const isFetcing = useRef( false );

  const timeZone = process.env.REACT_APP_TIMEZONE

// Format datetime for display
  const formatDateTime = (date) => {
    return moment(date).format('DD MMM YYYY, h:mm:ss A');
  };

  // Fetch admin settings and sync records
  useEffect(() => {
    dispatch(adminVerifyToken()).unwrap()
    .then((result) => {
      // console.log('vToken',result)
      // triggerToast( 'Manual sync successful','success' );
    })
    .catch((error) => {
      if(error.error){
        triggerToast('Session Expired', 'error' );
        logoutUser();
      }else{
        triggerToast(error.error, 'error' );
      }
    })
    .finally(() => {
      
    });

    
    var token = getToken();
    if(!token) logoutUser();
    // console.log('status',status)
    if ( !isFetcing.current ) {
      dispatch(fetchAdminSetting());
      dispatch(fetchManualSyncRecords());
      isFetcing.current = true
    }
  }, [status, dispatch]);

  // Check if fetch is finished with succes or error status.
  useEffect(() => {
    if ( status === 'succeeded' || status === 'error' ) isFetcing.current = false
}, [status]);


// Fetch admin settings and manual sync records on component mount
  useEffect(() => {
   
     // Update last sync date when manual sync records change
    if (manualSyncRecords && manualSyncRecords.data) {
      const lastRecord = manualSyncRecords.data[0];
      //console.log('lastRecord', new Date(lastRecord.createdat).toLocaleString());
      setLastUpdateDate(new Date(lastRecord.createdat).toLocaleString());
    }
  }, [manualSyncRecords]);

  // Handle Manual Sync
  const handleManualSync = () => {
    setRotating(true);
    dispatch(triggerManualSync())
   
  
      .unwrap()
      .then((result) => {
        dispatch(fetchManualSyncRecords())
        if (Array.isArray(result) && result.length > 0) {
          const lastRecord = result[0];
          setLastUpdateDate(new Date(lastRecord.createdat).toLocaleString());
          
        }
        triggerToast( 'Manual sync successful','success' );
      })
      .catch((error) => {
        triggerToast('Failed to sync', 'error' );
      })
      .finally(() => {
        setRotating(false);
      });
  };
// Get admin settings values for dynamic font sizes.

  setValue('iconFontSize', adminSettings.iconFontSize);
  

  
  const icnSize = getValue('iconFontSize' );



  //console.log(adminSettings)

  // Generate logo display
  const logoFile = ((adminSettings.logoImage) && (adminSettings.logoImage!==null))?<img src={`${adminSettings.logoImage}`} alt="Admin logo" style={{maxWidth:100, maxHeight:150}}/>:<strong>LOGO</strong>;
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const handleLogoutClick = () => setShowLogoutModal(true);
  const handleClose = () => setShowLogoutModal(false);

    // Confirm logout and handle navigation
  const handleLogoutConfirm = () => {
    if(isAuthenticated){
      // dispatch(logout());
      logout({ logoutParams: { returnTo: window.location.origin } });
      //navigate('/login');
    }
    dispatch(adminLogout())
      .unwrap()
      .then(() => {
        setValue('user_cur_page', 1);
        setValue('broadcast_cur_page', 1);
        setValue('devicehistory_cur_page', 1);
        setValue('loginhistory_cur_page', 1);
        triggerToast('Logged out successfully','success');
        navigate('/');
        window.location.reload();
      })
      .catch((error) => {
        triggerToast('Failed to logout' || 'error', error  );
        navigate('/');
        window.location.reload();
      })
      .finally(() => {
        setShowLogoutModal(false);
      });
  };

  


  return (
    <nav className="navbar navbar-expand-lg" style={{backgroundColor: adminSettings.sideMenuColor
    }}>
      <div className="container-fluid d-flex flex-lg-column h-100">
        <a className="navbar-brand"  href="/home" >
    { logoFile }
        
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse flex-column" id="navbarNav">
          <ul className="navbar-nav flex-column mt-2 mb-2">
            <li className="nav-item">
              <NavLink   to="/home" className={({ isActive }) => isActive ? "nav-link text-white active" : "nav-link text-white"}>
                <FaUser className='me-2 icon' style={{color: adminSettings.iconColor, fontSize: icnSize + "px" }} /><span>Users</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className={({ isActive }) => isActive ? "nav-link text-white active" : "nav-link text-white"} to="/broadcast">
                <LiaBroadcastTowerSolid className='me-2 icon'  style={{color: adminSettings.iconColor, fontSize: icnSize + "px" }}  /><span>Broadcast</span>
              </NavLink>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link d-flex" onClick={toggleDropdown}>
                <FaCog className='me-2 icon'  style={{color: adminSettings.iconColor, fontSize: icnSize + "px" }} />
                <span>Settings</span>
                <FaAngleDown className='ms-5' />
              </a>
              <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} style={{backgroundColor: adminSettings.sideMenuColor
    }}>
                <NavLink className={({ isActive }) => isActive ? "dropdown-item active" : "dropdown-item"} to="/adminpanelsettings">Admin Panel Settings</NavLink>
                <NavLink className={({ isActive }) => isActive ? "dropdown-item active" : "dropdown-item"} to="/mobileapp">Mobile App Settings</NavLink>
                <NavLink className={({ isActive }) => isActive ? "dropdown-item active" : "dropdown-item"} to="/integrationsetting">Integration Settings</NavLink>
              </div>
            </li>
            <li className="nav-item">
              <NavLink className={({ isActive }) => isActive ? "nav-link text-white active" : "nav-link text-white"} to="/dispositionlist">
                <FaRecycle className='me-2 icon'  style={{color: adminSettings.iconColor, fontSize: icnSize + "px" }} /><span>Disposition List</span>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink className={({ isActive }) => isActive ? "nav-link text-white active" : "nav-link text-white"} to="/loginhistory">
                <GrLogin className='me-2 icon'  style={{color: adminSettings.iconColor, fontSize: icnSize + "px" }} /><span>Login History</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className={({ isActive }) => isActive ? "nav-link text-white active" : "nav-link text-white"} to="/devicehistory">
                <MdDevices className='me-2 icon'  style={{color: adminSettings.iconColor, fontSize: icnSize + "px" }}  /><span>Device History</span>
              </NavLink>
            </li>
          </ul>
          <div >
            
            <NavLink to="#" className=" mt-5 logout" onClick={handleLogoutClick}>
              <IoIosPower className="me-2  icon"   style={{color: adminSettings.iconColor, fontSize: icnSize + "px" }}  /> <span>Logout</span>
            </NavLink>
            <NavLink 
              to="/changepassword" 
              className={({ isActive }) => isActive ? "nav-link text-white active" : "nav-link text-white"}
              style={{ 
                
                padding:'10px 8px'
                
               
                
              }}
            >
              <FaLock className="icon"  style={{color: adminSettings.iconColor, fontSize: icnSize + "px" }}  /> 
              <span>Change Password</span>
            </NavLink>
            <div className='text-white' style={{ fontSize: '16px', padding: '10px 8px' }} onClick={handleManualSync}>
  <IoMdSync 
    className={`icon me-2 ${rotating ? 'rotating' : ''}`} 
    style={{ color: adminSettings.iconColor, fontSize: icnSize + "px" }}  
  />
  <span>Manual Sync</span>
  {manualSyncRecords.data && (
    <p className="last-update">Last updated {formatDateTime(lastUpdateDate)}</p>
  )}
</div>
          </div>
        </div>
      </div>
      
      {/* Logout Confirmation Modal */}
      <Modal show={showLogoutModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="w-100 text-center">Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center " style={{fontSize:"20px"}}>
          Are you sure you want to logout?
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
        <Button variant="primary" className='w-25' onClick={handleLogoutConfirm} style={{ backgroundColor: adminSettings?.properties?.primaryButtonColor }} >
            Yes
          </Button>
          <Button variant="secondary" className='w-25' onClick={handleClose} style={{ backgroundColor: adminSettings?.properties?.secondaryButtonColor }} >
            No
          </Button>
          
        </Modal.Footer>
      </Modal>
    </nav>
  );
}

export default Navbar;
