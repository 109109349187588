// Imports
import React, { useState } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { triggerToast } from '../utils/toast';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../features/authSlice';
import './Style.css';


function SetNewPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const status = useSelector((state) => state.auth.status);
  const error = useSelector((state) => state.auth.error);
  
  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (newPassword === confirmPassword) {
      try {
        await dispatch(resetPassword({  newPassword })).unwrap();
        triggerToast("Password reset successfully.", 'success');
        setAlertVariant('success');
        setTimeout(() => navigate('/login'), 1500); // Navigate after 1.5 seconds
      } catch (err) {
        triggerToast(error || "Failed to reset password.", 'error');
        setAlertVariant('danger');
      }
    } else {
      triggerToast("Passwords do not match.", 'error');
      setAlertVariant('danger');
    }
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container fluid className="login-container d-flex flex-column align-items-center justify-content-center" style={{ minHeight: '100vh', backgroundColor: '#f8f9fa' }}>
      <h1 className="p-5  text-center text-white">Admin Panel</h1>
      <Container className="p-5 border rounded shadow-lg bg-white" style={{ maxWidth: "600px", width: '100%' }}>
        <h2 className="mb-4">Set New Password</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="newPassword" className="mb-3 position-relative">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type={showPassword ? "text" : "password"}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              style={{ paddingRight: '2.5rem' }} // Add padding to the right for the icon
            />
            <span
              onClick={togglePasswordVisibility}
              style={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
              className='mt-3'
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </Form.Group>
          <Form.Group controlId="confirmPassword" className="mb-3 position-relative">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type={showPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              style={{ paddingRight: '2.5rem' }} // Add padding to the right for the icon
            />
            <span
              onClick={togglePasswordVisibility}
              style={{
                position: 'absolute',
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
              className='mt-3'
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </Form.Group>
          {alertMessage && (
            <Alert variant={alertVariant} className="mt-3">
              {alertMessage}
            </Alert>
          )}
          <Button variant="primary" type="submit" className="w-50 mt-3 d-block mx-auto">
            Reset Password
          </Button>
        </Form>
      </Container>
    </Container>
  );
}

export default SetNewPassword;
