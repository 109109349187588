// Imports
import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import Navbar from './Navbar';
import { triggerToast } from '../utils/toast';
import { changePassword } from '../features/authSlice';
import { fetchAdminSetting } from '../features/settingSlice';
import { setValue, getValue } from '../utils/auth';


function ChangePassword() {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  // const [successMessage, setSuccessMessage] = useState('');

  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.auth);
  const adminSettings = useSelector((state) => state.setting.adminSettings);
  
  // Fetch admin settings on component mount
  useEffect(() => {
    dispatch(fetchAdminSetting());
  }, [dispatch]);

  // Get admin settings values for dynamic font sizes.
  setValue('labelFontSize', adminSettings.labelFontSize);
 
  setValue('headingFontSize', adminSettings?.properties?.headingFontSize);
  // setValue('subHeadingFontSize', adminSettings?.properties?.subHeadingFontSize);



 const hdngFontSize = getValue('headingFontSize');

 const lblFontSize = getValue('labelFontSize' );
 
 // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    // Check if new password and confirm password match
    if (newPassword !== confirmPassword) {
      triggerToast("New Password and Confirm Password do not match.", 'error');
      //alert('New Password and Confirm Password do not match');
      return;
    }


    // Dispatch changePassword action with required data
    dispatch(changePassword({ oldPassword, newPassword }))
      .unwrap()
      .then(() => {
        //setSuccessMessage('Password changed successfully.');
        triggerToast("Password changed successfully", 'success');
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
      })
      .catch((error) => {
        triggerToast(`Failed to change password: ${error.error}`, 'error');
        // console.log('error',error.error);
        //alert(`Failed to change password: ${error.message}`);
      });
  };

  return (
    <div className="home-container d-flex flex-column flex-lg-row">
      <Navbar />
      <main className="content flex-grow-1">
        <h2 className="mb-5" style={{fontSize: hdngFontSize + "px"}}>Change Password</h2>
        <Container className="bg-white rounded-2" style={{ padding: '20px' }}>
          <Form onSubmit={handleSubmit}>
            <Form.Group as={Row} controlId="oldPassword" className="mb-3">
              <Form.Label column sm="3" style={{ textAlign: 'right',fontSize: lblFontSize + "px" }}>Old Password</Form.Label>
              <Col>
              <Form.Control
                type="password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                required
              />
            </Col>
            <Col></Col>
            </Form.Group>
            
            <Form.Group as={Row} controlId="newPassword" className="mb-3">
              <Form.Label column sm="3" style={{ textAlign: 'right',fontSize: lblFontSize + "px" }}>New Password</Form.Label>
              <Col>
              <Form.Control
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              /></Col>
              <Col></Col>
            </Form.Group>
            <Form.Group as={Row} controlId="confirmPassword" className="mb-3">
              <Form.Label column sm="3" style={{ textAlign: 'right', fontSize: lblFontSize + "px" }}>Confirm Password</Form.Label>
              <Col>
              <Form.Control
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              /></Col>
              <Col></Col>
            </Form.Group>

            <Row>
            <Col sm="3"></Col>
            <Col>
            <Button
              variant="primary"
              type="submit"
              className="w-25 mt-4 d-block "
              disabled={status === 'loading'}
              style={{ backgroundColor: adminSettings?.properties?.primaryButtonColor }} 
            >
              {status === 'loading' ? 'Submitting...' : 'Submit'}
            </Button></Col>
            
            </Row>
            
          </Form>
        </Container>
      </main>
    </div>
  );
}

export default ChangePassword;
