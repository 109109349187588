// Sets the authentication token in localStorage
export const setToken = (token)=>{
    localStorage.setItem('auth_token', token);
}

// Retrieves the authentication token from localStorage
export const getToken = ()=>{
    const token = localStorage.getItem('auth_token');
    return token;
}

// Removes the authentication token from localStorage
export const removeToken = () => { 
    localStorage.removeItem('auth_token');
}

// Logs out the user by removing the token and redirecting to the home page
export const logoutUser = ()=>{
    removeToken();
    window.location = '/';
}

// Retrieves a value from localStorage by key
export const getValue = (key)=>{
    return localStorage.getItem(key)??null;
}

// Sets a value in localStorage with the specified key
export const setValue = (key,value)=>{
    localStorage.setItem(key, value);
}