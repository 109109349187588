import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { getToken } from './utils/auth';
import Login from './Components/Login';
import ForgotPassword from './Components/ForgotPassword';
import ChangePassword from './Components/changePassword'; // Ensure the import matches the file name
import SetNewPassword from './Components/SetNewPassword';
import Home from './Components/Home';
import Broadcast from './Components/Broadcast';
import AdminPanelSettings from './Components/AdminPanelSettings';
import MobileApp from './Components/MobileApp';
import IntegrationSetting from './Components/IntegrationSetting';
import DispositionList from './Components/DispositionList';
import LoginHistory from './Components/LoginHistory';
import DeviceHistory from './Components/DeviceHistory';
import AdminHistory from './Components/AdminHistory';
import MobileHistory from './Components/MobileHistory';

function App() {
  var token = getToken();
  //console.log('isAuthenticated token', token);
  const isAuthenticated = token??false;

  if(isAuthenticated){
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/changepassword" element={<ChangePassword />} /> {/* Ensure the component name is capitalized */}
          <Route path="/setnewpassword" element={<SetNewPassword />} />
          <Route path="/broadcast" element={<Broadcast />} />
          <Route path="/adminpanelsettings" element={<AdminPanelSettings />} />
          <Route path="/mobileapp" element={<MobileApp />} />
          <Route path="/integrationsetting" element={<IntegrationSetting />} />
          <Route path="/dispositionlist" element={<DispositionList />} />
          <Route path="/loginhistory" element={<LoginHistory />} />
          <Route path="/devicehistory" element={<DeviceHistory />} />
          <Route path="/adminhistory" element={<AdminHistory/>} />
          <Route path="/mobilehistory" element={<MobileHistory/>} />
        </Routes>
      </BrowserRouter>
    );
  }else{
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/*" element={<Login />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
