// Imports
import React, { useState, useEffect, useRef } from 'react';
import { Container, Table, Button, Card, Row, Col, Form, Toast, Pagination, InputGroup, FormControl } from 'react-bootstrap';
import { FaChevronLeft, FaChevronRight,FaSortUp, FaSortDown } from 'react-icons/fa';
import { IoIosSearch } from 'react-icons/io';
import { FiFilter } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllContacts, activeInactiveUsers } from '../features/userSlice';
import Navbar from './Navbar';
import AddUserModal from './AddUserModal';
import BulkUploadModal from './BulkUploadModal';
import DeactivateModal from './DeactivateModal';
// import { fetchAdminSetting } from '../features/settingSlice';
import { setValue, getToken, getValue } from '../utils/auth';
import moment from 'moment-timezone';


import axios from 'axios';
import './Style.css';

import { triggerToast } from '../utils/toast';

// Local States
function Home() {
  const isFetcingUser = useRef( false );
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  // const userData = useSelector((state) => state.users.users);
  const { users, activeInactiveUserCount, status, error } = useSelector((state) => state.users);
  // const activeInactiveUserCount = useSelector((state) => state.users.activeInactiveUserCount)
  const { adminSettings } = useSelector((state) => state.setting);
  const userData = users;

  const allUsers = userData.contacts;
  const usersPerPage = 10; //userData.pageSize
  const totalPages = userData.totalPages;
  //console.log('activeInactiveUsers',activeInactiveUserCount);
  
  setValue('user_page_size', usersPerPage);
  setValue('user_cur_page', currentPage);
  // const searchResults = useSelector((state) => state.users.searchResults);
  const [showModal, setShowModal] = useState(false);
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [sortKey, setSortKey] = useState('name');
  const [isAddUser, setIsAddUser] = useState(false); 
  const [sortDirection, setSortDirection] = useState(true); 
  const [newUser, setNewUser] = useState({
    name: '',
    mobile: '',
    agency: '',
    branch: '',
    status: 'Active',
    gender: '',
    dob: '',
    pan: '',
    occupation: '',
    reportsTo:''
  });

  const [deactivateStatus, setDeactivateStatus] = useState({
    temporaryInactiveDate: '',
    deactivateWipe: '',
    deactivateOpt: 'deactivate'
  });
  const [userStatus, setStatusFilter] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searching, setSearching] = useState(false);
  const pageIndex = (currentPage-1)*usersPerPage;
  // const base_url = 'https://sf-collection-api-0f31b037d478.herokuapp.com';
  const base_url = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    if(!searching){
      if ( !(isFetcingUser.current) ) {
        dispatch(fetchAllContacts({ 
          sort: sortDirection, 
          sortKey: sortKey, 
          searchTerm: searchTerm,
          activeStatus: userStatus
        }));
        dispatch(activeInactiveUsers());
        isFetcingUser.current = true
      }
    }
  }, [status,sortDirection, sortKey, dispatch,currentPage,searching, userStatus]);

  useEffect(() => {
    if ( status === 'succeeded' || status === 'error' ) isFetcingUser.current = false
}, [status]);

  useEffect(()=>{
    //console.log('users',allUsers)
    isSingleUserSelected();
  },[allUsers,activeInactiveUserCount, selectedUsers])

  // const activeUsers = (allUsers && allUsers.length > 0 ) ? allUsers.filter(user => user['is_active__c'] === true).length : 0;
  // const inactiveUsers = (allUsers && allUsers.length > 0 ) ? allUsers.filter(user => user['is_active__c'] === false).length : 0;
  const activeUsers = activeInactiveUserCount.activeCount;
  const inactiveUsers = activeInactiveUserCount.inactiveCount;
  //const activeInactiveUserCount = activeInactiveUsers();
  const displayUsers = (allUsers && allUsers.length > 0 )?allUsers.length:0;

  
// Handle Show Modal
  const handleShow = () => {
    setShowModal(true);
    setIsAddUser(true)
  };
  const handleBulkUploadShow = () => setShowBulkUploadModal(true);
  const handleClose = () =>{
    setShowModal(false);
    setIsAddUser(false)
  }
  const handleBulkUploadClose = () => setShowBulkUploadModal(false);
  const handleDeactivateShow = () => setShowDeactivate(true);
  const handleDeactivateClose = () => setShowDeactivate(false);
  const [showFilterBox, setShowFilterBox] = useState(false);
  const handleToastClose = () => setShowToast(false);
  const [deactivateDate, setDeactivateDate] = useState(new Date());
  
  const [isActiveUserSelected, setActiveUserSelected] = useState(false);
  const [isInactiveUserSelected, setInactiveUserSelected] = useState(false);
  
 // Get admin settings values for dynamic font sizes.
  setValue('labelFontSize', adminSettings.labelFontSize);
  setValue('iconFontSize', adminSettings.iconFontSize);
  setValue('tableHeadingFontSize', adminSettings?.properties?.tableHeadingFontSize);
  setValue('tableDetailsFontSize', adminSettings?.properties?.tableDetailsFontSize);
  setValue('headingFontSize', adminSettings?.properties?.headingFontSize);
  setValue('subHeadingFontSize', adminSettings?.properties?.subHeadingFontSize);

  // const icnSize = getValue('iconFontSize' );
  const tblHeadingFontSize = getValue('tableHeadingFontSize');
 const tblDetailsFontSize = getValue('tableDetailsFontSize');
 const hdngFontSize = getValue('headingFontSize');
 const subHdngFontSize = getValue('subHeadingFontSize')
  
// Handle Change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleSort = (key) => {
   
    if (sortKey === key) {
      setSortDirection(!sortDirection); 
    } else {
     
      setSortKey(key);
      setSortDirection(true);
    }
  
    
    // dispatch(fetchAllContacts({ 
    //   sort: sortDirection, 
    //   sortKey: key 
    // }));
  };

  // Handle Reset
  const handleReset = (e) => {
    setNewUser({
      name: '',
      mobile: '',
      agency: '',
      branch: '',
      status: 'Active',
      gender: '',
      dob: '',
      pan: '',
      occupation: '',
      reportsTo:''
    });
  };

  // Handle Click outside to close the filter box.
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showFilterBox && !document.getElementById("filter-box").contains(event.target)) {
        setShowFilterBox(false);
      }
    };
  
    document.addEventListener("mousedown", handleClickOutside);
  
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showFilterBox]);
  
// Handle Filter Change
  const handleFilterChange = (event) => {
    setStatusFilter(event.target.value);
    let uStatus = event.target.value;
    let filterObject = [];
    filterObject.sort = sortDirection;
    filterObject.sortKey = sortKey;
    if (!event.target.value || (event.target.value.length>3)) {
      filterObject.searchTerm = searchTerm;
    }
    if( (uStatus !== undefined) && (uStatus !== '')){
      filterObject.activeStatus = uStatus;
    }
    setShowFilterBox(false);
    //// dispatch(fetchAllContacts(filterObject));
  };

  // Handle Deactivate
  const handleDeactivateChange = (temporaryInactiveDate, deactivateWipe, deactivateOpt) => {
    //console.log('deactivateWipe', deactivateWipe);
    //const { temporaryInactiveDate, deactivateWipe } = e.target;
    setDeactivateStatus({
      temporaryInactiveDate: temporaryInactiveDate,
      deactivateWipe: deactivateWipe,
      deactivateOpt: deactivateOpt
    });
    // console.log('deactivateStatus', deactivateStatus);
    
  };

  const timeZone = process.env.REACT_APP_TIMEZONE

// Format datetime for display
const formatDateTime = (date) => {
  return moment(date).format('DD MMM YYYY, h:mm:ss A');
};

// Handle Submit
  //console.log('deactivateStatus', deactivateStatus);
  const handleSubmit = async (requestBody) => {
    // console.log("requestBody", requestBody);
    try {
      handleClose();
      triggerToast("Contact creation is in progress.", 'info');
      const response = await axios.post(`${base_url}/api/user/createContact`, requestBody, {
        headers: { 
          Authorization: `Bearer ${getToken()}`,
        }
      });
  
      // console.log('API Response:', response.data);
      triggerToast(response.data.message, 'success');
      
      dispatch(activeInactiveUsers());
      dispatch(fetchAllContacts(''));
    } catch (error) {
      let errorMessage = 'An unexpected error occurred.';
  
      if (error.response && error.response.data && error.response.data.error) {
        switch (error.response.data.error) {
          case 'Contact with same phone number already exists':
            errorMessage = 'Contact with the same phone number already exists.';
            break;
          case 'Contact with same email already exists':
            errorMessage = 'Contact with the same email already exists. ';
            break;
          case 'Contact with same pan number already exists':
            errorMessage = 'Contact with the same PAN number already exists. ';
            break;
          default:
            errorMessage = error.response.data.error;
            break;
        }
      }
  
      triggerToast(errorMessage, 'error');
      // console.error('API Error:', error);
    }
  };
  const handleActivate = async(e) =>{
    try{
      // console.log('selectedUsers',selectedUsers)
      const requestBody = JSON.parse('{"sfids":["'+selectedUsers.join('","')+'"],"activeStatus":true,"temporaryInactiveDate":""}');
      const response = await axios.put(`${base_url}/api/user/updateActiveStatus`, requestBody, {
      headers: { 
        Authorization: `Bearer ${getToken()}`,
      }});
      triggerToast(response.data.message, 'success');
      dispatch(activeInactiveUsers());
      dispatch(fetchAllContacts(''));
      setSelectedUsers([]);
    } catch (error) {
      triggerToast(error.message, 'error');
      // console.error('API Error:', error);
    }
  }

  //const handleInActivate = async(e) =>{
    const handleDeactivateSubmit = async(e) =>{
    try{

      // console.log('handleDeactivateSubmit deactivateStatus', deactivateStatus, deactivateDate);
      // return;
      //console.log('selectedUsers',selectedUsers)
      let requestBody = JSON.parse('{"sfids":["'+selectedUsers.join('","')+'"],"activeStatus":false}');
      if(deactivateStatus.deactivateWipe){
        requestBody = JSON.parse('{"sfids":["'+selectedUsers.join('","')+'"],"activeStatus":false,"status":"inactiveWipe"}');
      }else if(deactivateStatus.deactivateOpt === 'temporaryInactive'){
        requestBody = JSON.parse('{"sfids":["'+selectedUsers.join('","')+'"],"activeStatus":false,"temporaryInactiveDate":"'+(deactivateDate)+'"}');
      }
      // console.log('requestBody',requestBody)
      // return;
      const response = await axios.put(`${base_url}/api/user/updateActiveStatus`, requestBody, {
      headers: { 
        Authorization: `Bearer ${getToken()}`,
      }});
      triggerToast(response.data.message, 'success');
      // triggerToast("Updated successfully", 'success');
      dispatch(activeInactiveUsers());
      dispatch(fetchAllContacts(''));
      setSelectedUsers([]);
      setDeactivateDate(new Date());
      setDeactivateStatus({
        temporaryInactiveDate: '',
        deactivateWipe: '',
        deactivateOpt: 'deactivate'
      });
    } catch (error) {
      triggerToast(error.message, 'error');
      // console.error('API Error:', error);
    }
  }

  // Handle Search
  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    
    if (searchTerm && searchTerm.length>2) {
      setCurrentPage(1);
      setValue('user_cur_page', 1);
      let filterObject = {};
      filterObject.searchTerm = searchTerm;
      if (userStatus) {
        filterObject.activeStatus = userStatus;
      }
      setSearching(true);
      dispatch(fetchAllContacts(filterObject));
    } else {
      setSearching(false);
      let filterObject = {};
      if (userStatus) {
        filterObject.activeStatus = userStatus;
      }
      //dispatch(fetchAllContacts(filterObject));
    }
  };

  //const displayUsers = searchTerm ? searchResults : allUsers;
  //const displayUsers = allUsers;
  
  // Handle Pagination
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setValue('user_cur_page', pageNumber);
  }
  
  const startPage = Math.max(currentPage, 1);
  const endPage = Math.min(startPage + 2, totalPages);
  const pageNumbers = [];
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  const selectAll = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setActiveUserSelected(true);
      setInactiveUserSelected(true);
      setSelectedUsers(users.map(user => user.sfid));
    } else {
      setActiveUserSelected(false);
      setInactiveUserSelected(false);
      setSelectedUsers([]);
    }
  };

  const selectUser = (e, userId) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedUsers([...selectedUsers, userId]);
    } else {
      setSelectedUsers(selectedUsers.filter(id => id !== userId));
    }
  };

  // const isAnyUserSelected = selectedUsers.length > 0;

  const isSingleUserSelected = ()=>{
    if( (selectedUsers.length === 1) && allUsers){
      const CurUser = allUsers.filter(user => user.sfid === selectedUsers[0])
      if(CurUser[0].is_active__c === true){
        setActiveUserSelected(true);
      }else{
        setInactiveUserSelected(true);
      }
      
      //console.log('isSingleUserSelected', selectedUsers, CurUser[0].is_active__c);
    }else{
      setActiveUserSelected(selectedUsers.length > 1);
      setInactiveUserSelected(selectedUsers.length > 1);
    }
  };
  return (
    <div className="home-container d-flex flex-column flex-lg-row">
      <Navbar />
      <main className="content flex-grow-1">
        <Container className="page-background">
          <div className="dashboard">
            <h3 className="mb-3" style={{fontSize: hdngFontSize + "px"}}>Dashboard</h3>
            <Row className="mb-3 rounded-2 d-flex">
              <Col md={4} className="mb-3">
                <Card className="card-active-users">
                  <Card.Body className="d-flex align-items-center justify-content-center">
                    <div className="d-flex align-items-center">
                      <span className="fw-bold fs-2 me-4">{activeUsers}</span> 
                      <span>Active Users</span>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4} className="mb-3">
                <Card className="card-inactive-users">
                  <Card.Body className="d-flex align-items-center justify-content-center">
                    <div className="d-flex align-items-center">
                      <span className="fw-bold fs-2 me-4">{inactiveUsers}</span>
                      <span>Inactive Users</span>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
          <Container className="pt-2">
            <h4 style={{fontSize: hdngFontSize + "px"}}>List of Users</h4>
            <p style={{fontSize: subHdngFontSize + "px"}}>List of Field agents user for Mobile App</p>
            <div className="text-center my-2 table-hover rounded-top custom-table justify-content-center">
              <div className="row p-2">
                <div className="col-12 col-md-4">
                  <Form className="d-flex search" role="search">
                    <InputGroup className="border rounded">
                      <InputGroup.Text className="bg-white border-0">
                        <IoIosSearch />
                      </InputGroup.Text>
                      <FormControl
                        type="search"
                        placeholder="Search "
                        aria-label="Search"
                        className="search-bar text-center border-0"
                        value={searchTerm}
                        onChange={handleSearch}
                      />
                    </InputGroup>
                  </Form>
                </div>
                <div className="col-12 col-md-8 d-flex flex-column flex-md-row justify-content-end align-items-center mt-2 mt-md-0">
                  <Button className="me-md-2 mb-2 mb-md-0 add-user" onClick={handleShow} disabled={selectedUsers.length > 0} style={{ backgroundColor: adminSettings?.properties?.primaryButtonColor }} > 
                    Add User
                  </Button>
                  <Button className="me-md-2 mb-2 mb-md-0 bulk-upload" onClick={handleBulkUploadShow} disabled={selectedUsers.length > 0} style={{ backgroundColor: adminSettings?.properties?.primaryButtonColor }}> 
                    Bulk Upload
                  </Button>
                  <Button
                    variant={(isInactiveUserSelected) ? 'outline-light' : 'secondary'}
                    className="me-md-2 mb-2 mb-md-0 btn-user"
                    disabled={!isInactiveUserSelected}
                    onClick={handleActivate}
                    style={{ backgroundColor: adminSettings?.properties?.secondaryButtonColor }}
                  >
                    Activate
                  </Button>
                  <Button
                    variant={isActiveUserSelected ? 'outline-light' : 'secondary'}
                    className="me-md-4 me-1 btn-user"
                    disabled={!isActiveUserSelected}
                    //onClick={handleInActivate}
                    onClick={handleDeactivateShow}
                    style={{ backgroundColor: adminSettings?.properties?.secondaryButtonColor }}
                  >
                    Deactivate
                  </Button>
                </div>
              </div>
              <Table responsive>
                <thead >
                  <tr>
                    <th>
                      <Form.Check type="checkbox" onChange={selectAll} />
                    </th>
                    <th style={{fontSize: tblHeadingFontSize + "px"}}>Sr. No.</th>
                    <th
        className="text-start"
        style={{fontSize: tblHeadingFontSize + "px", cursor: 'pointer'}}
        onClick={() => handleSort('name')}
      >
        Name
        <span className="ms-2 align-items-center">
        {sortKey === 'name' && sortDirection ? <FaSortUp /> : <FaSortDown />}
        </span>
      </th>
      <th
        className="text-center"
        style={{fontSize: tblHeadingFontSize + "px", cursor: 'pointer'}}
        onClick={() => handleSort('mobilephone')}
      >
        Mobile
        <span className="ms-2 align-tems-center">
        {sortKey === 'mobilephone' && sortDirection ? <FaSortUp /> : <FaSortDown />}
        </span>
      </th>
      <th
        className="text-center"
        style={{fontSize: tblHeadingFontSize + "px", cursor: 'pointer'}}
        onClick={() => handleSort('agencyName')}
      >
        Agency
        <span className="ms-2 align-tems-center">
        {sortKey === 'agencyName' && sortDirection ? <FaSortUp /> : <FaSortDown />}
        </span>
      </th>
      <th
        className="text-center"
        style={{fontSize: tblHeadingFontSize + "px", cursor: 'pointer'}}
        onClick={() => handleSort('branchName')}
      >
        Branch
        <span className="ms-2 align-tems-center">
        {sortKey === 'branchName' && sortDirection ? <FaSortUp /> : <FaSortDown />}
        </span>
      </th>
                    <th className="text-center" style={{fontSize: tblHeadingFontSize + "px"}}>Max Limit</th>
                    <th className="text-center" style={{fontSize: tblHeadingFontSize + "px"}}>Logged In</th>
                    <th className="text-start" style={{fontSize: tblHeadingFontSize + "px"}}>
                      Status 
                      <span style={{ position:"relative"}}>
                        <FiFilter className="ms-2 mb-1" onClick={() => setShowFilterBox(!showFilterBox)} />

                        {showFilterBox && (
  <div id="filter-box" style={{ background: '#f8f9fa', padding: '10px', marginBottom: '10px', position: "absolute", right: 0, width: "120px" }}>
    <Form.Group controlId="userStatus" className="d-flex flex-column align-items-start">
      <Form.Check type="radio" name="userStatus" label="All" value="" checked={userStatus === ""} onChange={handleFilterChange} />
      <Form.Check type="radio" name="userStatus" label="Active" value="active" checked={userStatus === "active"} onChange={handleFilterChange} />
      <Form.Check type="radio" name="userStatus" label="Inactive" value="inactive" checked={userStatus === "inactive"} onChange={handleFilterChange} />
    </Form.Group>
  </div>
                          )}
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                {(allUsers?.length>0)?(
                  (allUsers || []).map((user, index) => (
                    <tr key={user.sfid} className={index % 2 === 0 ? 'bg-light' : ''}>
                      <td className="text-center">
                        <Form.Check
                          type="checkbox"
                          checked={selectedUsers.includes(user.sfid)}
                          onChange={(e) => selectUser(e, user.sfid)}
                        />
                      </td>
                      <td className="text-center" style={{fontSize: tblDetailsFontSize + "px"}}>{(pageIndex+index)+ 1}</td>
                      <td className="text-start" style={{fontSize: tblDetailsFontSize + "px"}}>{user.name}</td>
                      <td className="text-center" style={{fontSize: tblDetailsFontSize + "px"}}>{user.mobilephone}</td>
                      <td className="text-center" style={{fontSize: tblDetailsFontSize + "px"}}>{user.contactAccount?.name}</td>
                      <td className="text-center" style={{fontSize: tblDetailsFontSize + "px"}}>{user.Banker?.BranchUnitBusinessMember?.branchUnit?.name}</td>
                      <td className="text-center" style={{fontSize: tblDetailsFontSize + "px"}}>{user.maximum_cash_collection_limit__c !== null 
    ? user.maximum_cash_collection_limit__c 
    : "N/A"}</td>
                      <td style={{fontSize: tblDetailsFontSize + "px"}}>{user.LoginHistories.length > 0 ? formatDateTime(user.LoginHistories[0]?.login_date_time__c) : 'No login history'}</td>
                      <td className="text-center" style={{fontSize: tblDetailsFontSize + "px"}}>
                      <span className="d-flex align-items-center justify-content-start" style={{ color: user['is_active__c'] === true ? 'green' : 'red' }}>
  <span className="dot" style={{ height: '10px', width: '10px', backgroundColor: user['is_active__c'] === true ? 'green' : 'red', borderRadius: '50%', display: 'inline-block', marginRight: '5px' }}></span>
  {user['is_active__c'] === true ? 'Active' : 'Inactive'}
  {user['in_active_till_date__c'] && (
    <div style={{ fontSize: '12px', color: 'gray',  marginLeft: '10px' }}>
      Till {new Date(user['in_active_till_date__c']).toLocaleDateString()}
    </div>
  )}
</span>
</td>
                    </tr>
                  ))):(
                    <tr key={1}>
                      <td colSpan={7} className="text-center">No Record Found</td>                      
                    </tr>
                  )}
                </tbody>
              </Table>
              <Pagination className="justify-content-end mb-3 me-2">
                <Pagination.Prev
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <FaChevronLeft className="text-dark" />
                </Pagination.Prev>

                {pageNumbers.map((page) => (
                  <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => paginate(page)}
                    className="ms-1 me-1"
                  >
                    {page}
                  </Pagination.Item>
                ))}

                <Pagination.Next
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  <FaChevronRight className="text-dark" />
                </Pagination.Next>
              </Pagination>
            </div>
            
          </Container>
        </Container>
      </main>

      {/* Add User Modal */}
      <AddUserModal
        show={showModal}
        handleClose={handleClose}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handleReset={handleReset}
        newUser={newUser}
        setNewUser={setNewUser}
      />

      {/* Bulk Upload Modal */}
      <BulkUploadModal
        show={showBulkUploadModal}
        handleClose={handleBulkUploadClose}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />

    <DeactivateModal
        show={showDeactivate}
        handleDeactivateClose={handleDeactivateClose}
        handleDeactivateChange={handleDeactivateChange}
        handleDeactivateSubmit={handleDeactivateSubmit}
        setDeactivateDate={setDeactivateDate}
        deactivateDate={deactivateDate}
      />

      <Toast
        onClose={handleToastClose}
        show={showToast}
        delay={3000}
        autohide
        style={{
          position: 'absolute',
          top: 20,
          right: 20,
        }}
      >
        <Toast.Body>User added successfully!</Toast.Body>
      </Toast>
    </div>
  );
}

export default Home;
