// Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken, logoutUser } from '../utils/auth';

// Base URL for API requests
//const base_url = 'https://sf-collection-api-0f31b037d478.herokuapp.com';
const base_url = process.env.REACT_APP_API_BASE_URL;

// Async thunk for fetching agencies
export const fetchAgencies = createAsyncThunk('adduser/fetchAgencies', async () => {
  const response = await axios.get(`${base_url}/api/user/getAllAgencies`, {
    headers: { 
      Authorization: `Bearer ${getToken()}`,
    }
  });
  // console.log('Agencies Response:', response.data); // Log the response
  return response.data.agencies; // Return only the agencies array
});

// Async thunk for fetching branches
export const fetchBranches = createAsyncThunk('adduser/fetchBranches', async () => {
  const response = await axios.get(`${base_url}/api/user/getAllBranches`, {
    headers: { 
      Authorization: `Bearer ${getToken()}`, 
    }
  });
  // console.log('Branches Response:', response.data); // Log the response
  return response.data.agencies; // Return only the branches array
});

// Slice for managing adduser state
const adduserSlice = createSlice({
  name: 'adduser',
  initialState: {
    agencies: [],
    branches: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgencies.fulfilled, (state, action) => {
        state.agencies = Array.isArray(action.payload) ? action.payload : [];
        state.status = 'succeeded';
      })
      .addCase(fetchBranches.fulfilled, (state, action) => {
        state.branches = Array.isArray(action.payload) ? action.payload : [];
        state.status = 'succeeded';
      })
      .addCase(fetchAgencies.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBranches.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAgencies.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        if(action.error.code === "ERR_BAD_REQUEST"){
          logoutUser();
        }
      })
      .addCase(fetchBranches.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        if(action.error.code === "ERR_BAD_REQUEST"){
          logoutUser();
        }
      });
  },
});

export default adduserSlice.reducer;
