// Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken } from '../utils/auth';

// Base URL for API requests
const baseURL = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  manualSyncRecords: [],
  status: 'idle',
  error: null,
};

// Thunk for triggering manual sync
export const triggerManualSync = createAsyncThunk('manualSync/triggerManualSync', async () => {
  const response = await axios.post(`${baseURL}/api/visits/manualSync`, null, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  return response.data;
});

// Thunk for fetching manual sync records
export const fetchManualSyncRecords = createAsyncThunk('manualSync/fetchManualSyncRecords', async () => {
  const response = await axios.get(`${baseURL}/api/visits/getManualSyncRecord`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  return response.data;
});

// Slice for managing manual sync state
const manualSyncSlice = createSlice({
  name: 'manualSync',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    
      .addCase(triggerManualSync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(triggerManualSync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        
      })
      .addCase(triggerManualSync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        if (action.error.code === "ERR_BAD_REQUEST") {
          // logoutUser();
        }
      })

      
      .addCase(fetchManualSyncRecords.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchManualSyncRecords.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.manualSyncRecords = action.payload;
      })
      .addCase(fetchManualSyncRecords.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        if (action.error.code === "ERR_BAD_REQUEST") {
          // logoutUser();
        }
      });
  },
});

export default manualSyncSlice.reducer;
