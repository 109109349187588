// Imports
import { toast } from 'react-toastify';

// Displays a toast notification with a specified message and type
export const triggerToast = (message, type)=>{
    type = type || 'default'; // Default type is 'default'

    if(type === 'info'){
        toast.info(message); // Display info toast
    }else if(type === 'success'){
        toast.success(message); // Display success toast
    }else if(type === 'warning'){
        toast.warn(message); // Display warning toast
    }else if(type === 'error'){
        toast.error(message); // Display error toast
    }else{
        toast(message); // Display default toast
    }
}