// features/deviceHistorySlice.js
// Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken, logoutUser, getValue } from '../utils/auth';

// Base URL for API requests
// const baseURL = 'https://sf-collection-api-0f31b037d478.herokuapp.com';
const baseURL = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  devices: [],
  status: 'idle',
  error: null,
};

// Thunk for fetching device history
export const fetchDeviceHistory = createAsyncThunk('deviceHistory/fetchDeviceHistory', async (params ) => {
  let pageNum = getValue('devicehistory_cur_page')??1;
  let size = getValue('devicehistory_page_size')??10;
  let sort = params.sort ?? true;
  let sortKey = params.sortKey ?? 'name';
  let otherParam = '';
  let searchTerm = params.searchTerm??'';
  // console.log('searchTerm',params)
  if (sort !== '') otherParam += `&sort=${sort}`;
  if (sortKey !== '') otherParam += `&sortKey=${sortKey}`;
  const response = await axios.get(`${baseURL}/api/user/getAllContactsUserDevices?page=${pageNum}&pageSize=${size}&search=${searchTerm}${otherParam}`, {
    headers: {
        Authorization: `Bearer ${getToken()}`,
    },
  });
  return response.data;
});

// Slice for managing device history state
const deviceHistorySlice = createSlice({
  name: 'deviceHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDeviceHistory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDeviceHistory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.devices = action.payload;
      })
      .addCase(fetchDeviceHistory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        if(action.error.code === "ERR_BAD_REQUEST"){
          logoutUser();
        }
      });
  },
});

export default deviceHistorySlice.reducer;
