// Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken, logoutUser, getValue } from '../utils/auth';

// Base URL for API requests
// const baseURL = 'https://sf-collection-api-0f31b037d478.herokuapp.com';
const baseURL = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  users: [],
  searchResults: [],
  activeInactiveUserCount: [],
  status: 'idle',
  searchStatus: 'idle',
  error: null,
};

// Async thunk for fetch all contacts
export const fetchAllContacts = createAsyncThunk('users/fetchAllContacts', async (params) => {
  let pageNum = getValue('user_cur_page')??1;
  let size = getValue('user_page_size')??10;
  let searchTerm = params.searchTerm??'';
  let agencyId = params.agencyId??'';
  let branchId = params.branchId??'';
  let activeStatus = ((params.activeStatus!==undefined))?params.activeStatus:'';
  let sort = params.sort ?? true;
  let sortKey = params.sortKey ?? 'name';
  let otherParam = '';

  if(params.isBroadcastPage){
    pageNum = getValue('broadcast_cur_page')??1;
    size = getValue('broadcast_page_size')??10;
  }
  
  if(searchTerm!=='') otherParam += `&search=${searchTerm}`;
  if(agencyId!=='') otherParam += `&agencyId=${agencyId}`;
  if(branchId!=='') otherParam += `&branchId=${branchId}`;
  if(activeStatus!=='') otherParam += `&activeStatus=${activeStatus}`;
  if (sort !== '') otherParam += `&sort=${sort}`;
  if (sortKey !== '') otherParam += `&sortKey=${sortKey}`;
  // console.log('fetchAllContacts', otherParam);
  // console.log('params',params)
  const response = await axios.get(`${baseURL}/api/user/getAllContacts?page=${pageNum}&pageSize=${size}${otherParam}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  return response.data;
});

// Async thunk for contact autocomplete
export const fetchContactsAutocomplete = createAsyncThunk('users/fetchContactsAutocomplete', async (searchVal) => {
  let pageNum = 1;
  let size = 10;
  let searchTerm = searchVal??'';
  let otherParam = '';

  
  if(searchTerm!=='') otherParam += `&search=${searchTerm}`;

  otherParam += `&activeStatus=active`;
  otherParam += `&sort=true`;
  otherParam += `&sortKey=name`;
// console.log('fetchContactsAutocomplete', otherParam);
  // console.log('params',params)
  const response = await axios.get(`${baseURL}/api/user/getAllContacts?page=${pageNum}&pageSize=${size}${otherParam}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  return response.data;
});

// Async thunk for Search
export const searchUsers = createAsyncThunk('users/searchUsers', async (searchTerm) => {
  const response = await axios.get(`${baseURL}/api/user/getUserContact?search=${searchTerm}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  return response.data;
});

// Async thunk for Active inactive user
export const activeInactiveUsers = createAsyncThunk('user/getActiveInactiveStatusCount', async (userId) => {
  const response = await axios.get(
    `${baseURL}/api/user/getActiveInactiveStatusCount`,{
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
  return response.data;
});

// export const deactivateUser = createAsyncThunk('users/deactivateUser', async (userId) => {
//   const response = await axios.post(
//     `${baseURL}/api/users/${userId}/deactivate`,
//     {},
//     {
//       headers: {
//         Authorization: `Bearer ${getToken()}`,
//       },
//     }
//   );
//   return response.data;
// });

// Slice for managing user state
const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllContacts.pending, (state) => {
        state.status = 'loading';
        state.users = [];
      })
      .addCase(fetchAllContacts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload;
      })
      .addCase(fetchAllContacts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.users = [];
        if(action.error.code === "ERR_BAD_REQUEST"){
          logoutUser();
        }
      })
      .addCase(fetchContactsAutocomplete.pending, (state) => {
        state.searchStatus = 'loading';
      })
      .addCase(fetchContactsAutocomplete.fulfilled, (state, action) => {
        state.searchStatus = 'succeeded';
        state.searchResults = action.payload;
      })
      .addCase(fetchContactsAutocomplete.rejected, (state, action) => {
        state.searchStatus = 'failed';
        state.error = action.error.message;
        if(action.error.code === "ERR_BAD_REQUEST"){
          logoutUser();
        }
      })

      .addCase(activeInactiveUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(activeInactiveUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.activeInactiveUserCount = action.payload;
      })
      .addCase(activeInactiveUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        if(action.error.code === "ERR_BAD_REQUEST"){
          logoutUser();
        }
      });
      
      // .addCase(activateUser.pending, (state) => {
      //   state.status = 'loading';
      // })
      // .addCase(activateUser.fulfilled, (state, action) => {
      //   state.status = 'succeeded';
      //   state.users = state.users.map((user) =>
      //     user.id === action.payload.id ? action.payload : user
      //   );
      // })
      // .addCase(activateUser.rejected, (state, action) => {
      //   state.status = 'failed';
      //   state.error = action.error.message;
      //   if(action.error.code == "ERR_BAD_REQUEST"){
      //     logoutUser();
      //   }
      // })
      // .addCase(deactivateUser.pending, (state) => {
      //   state.status = 'loading';
      // })
      // .addCase(deactivateUser.fulfilled, (state, action) => {
      //   state.status = 'succeeded';
      //   state.users = state.users.map((user) =>
      //     user.id === action.payload.id ? action.payload : user
      //   );
      // })
      // .addCase(deactivateUser.rejected, (state, action) => {
      //   state.status = 'failed';
      //   state.error = action.error.message;
      //   if(action.error.code == "ERR_BAD_REQUEST"){
      //     logoutUser();
      //   }
      // });
  },
});

export default userSlice.reducer;
