import { configureStore } from '@reduxjs/toolkit';
import authReducer from './features/authSlice';
import userReducer from './features/userSlice';
import loginHistoryReducer from './features/loginHistorySlice';
import dispositionListReducer from './features/dispositionSlice';
import deviceHistoryReducer from './features/deviceHistorySlice';
import adduserReducer from './features/adduserSlice';
import settingReducer from './features/settingSlice';
import broadcastReducer from './features/broadcastSlice';
import manualSyncReducer from './features/manualSyncSlice';
import adminHistoryReducer from './features/adminHistorySlice'; 
import mobileHistoryReducer from './features/mobileHistorySlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    users: userReducer,
    dispositionList:dispositionListReducer,
    loginHistory: loginHistoryReducer,
    deviceHistory: deviceHistoryReducer,
    adduser: adduserReducer,
    setting: settingReducer,
    broadcast:broadcastReducer,
    manualSync: manualSyncReducer,
    adminHistory: adminHistoryReducer,  
    mobileHistory: mobileHistoryReducer, 
  }
});

export default store;
