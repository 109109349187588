// Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setToken, getToken,removeToken } from '../utils/auth';
import axios from 'axios';

// Base URL for API requests
// const baseURL = 'https://sf-collection-api-0f31b037d478.herokuapp.com';
const baseURL = process.env.REACT_APP_API_BASE_URL;

// Async thunk for login
export const adminLogin = createAsyncThunk(
  'auth/adminLogin',
  async ({ email, password, sso_token }, { rejectWithValue }) => {
    try {
      let loginPayload;
      if(sso_token){
        loginPayload = {
          email,
          sso_token
        };
      }else{
        loginPayload = {
          email,
          password
        };
      }
      const response = await axios.post(`${baseURL}/api/admin/login`, loginPayload);
      if (response.data && response.data.token) {
        setToken(response.data.token);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for admin logout
export const adminLogout = createAsyncThunk(
  'auth/adminLogout',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/admin/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${getToken()}`, 
          },
        }
      );
      removeToken(); 
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for admin verifyToken
export const adminVerifyToken = createAsyncThunk(
  'auth/verifyToken',
  async (_, { rejectWithValue }) => {
    try {
      let data = JSON.stringify({
        "token": getToken()
      });
      const response = await axios.post(
        `${baseURL}/api/admin/verifyToken`,
        data,{
        headers: { 
          'Content-Type': 'application/json'
        }
      }
      );
      return response.data;
    } catch (error) {
      // console.log('adminVerifyToken',error.response.status)
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for change password
export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async ({ oldPassword, newPassword }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseURL}/api/admin/change-password`,
        { oldPassword, newPassword },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for forgot password
export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${baseURL}/api/admin/forget-password`, {
        email,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Invalid email');
    }
  }
);

// Async thunk for reset password
export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async ({ token, newPassword }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${baseURL}/api/admin/reset-password`, {
        token,
        newPassword,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Slice for managing authentication state
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    token: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    logout(state) {
      state.user = null;
      state.token = null;
      state.error = null; // Clear error on logout
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(adminLogin.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(adminLogin.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.error = null; // Clear error on successful login
      })
      .addCase(adminLogin.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(adminLogout.pending, (state) => { // Added handling for logout pending state
        state.status = 'loading';
      })
      .addCase(adminLogout.fulfilled, (state) => { // Added handling for successful logout
        state.status = 'succeeded';
        state.user = null; // Clear user on successful logout
        state.token = null; // Clear token on successful logout
        state.error = null; // Clear error on logout
      })
      .addCase(adminLogout.rejected, (state, action) => { // Added handling for logout failure
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(changePassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload ;
      })
      .addCase(resetPassword.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(adminVerifyToken.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(adminVerifyToken.fulfilled, (state, action) => {
        // console.log('adminVerifyToken',  action.payload);
        state.status = 'succeeded';
      })
      .addCase(adminVerifyToken.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { logout, resetError } = authSlice.actions;

export default authSlice.reducer;
