// Imports
import React, { useEffect, useState,useRef } from 'react';
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAgencies, fetchBranches } from '../features/adduserSlice';
// import { fetchAdminSetting } from '../features/settingSlice';
import {  getValue } from '../utils/auth';
import {fetchContactsAutocomplete } from '../features/userSlice';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

// Component to handle the Add User Modal with form, validation, and Redux state management.
function AddUserModal({ show, handleClose, handleChange, handleSubmit, handleReset, newUser,setNewUser }) {
  const dispatch = useDispatch();
  const isFetcingUserFilter = useRef( false );
  const isFetcingBranch = useRef( false );
  const [searchTerm, setSearchTerm] = useState("");
  const { agencies, branches, status, error } = useSelector((state) => state.adduser);
  const adminSettings = useSelector((state) => state.setting.adminSettings);
  // const userData = useSelector((state) => state.users.users);
  const userData = useSelector((state) => state.users.searchResults);
  const stateUsers = useSelector((state) => state.users.searchStatus);
  const users = userData.contacts ? userData.contacts : [];
 
 
  // setValue('labelFontSize', adminSettings.labelFontSize);

  // setValue('headingFontSize', adminSettings?.properties?.headingFontSize);

  // Fetch user filter results based on search term.
  useEffect(() => {
    if (!isFetcingUserFilter.current) {
      dispatch(fetchContactsAutocomplete(searchTerm));
      isFetcingUserFilter.current = true;
    }
  }, [stateUsers,searchTerm, dispatch ]);


   // Handle search and selection for 'Reports To' field.
  const handleOnSearch = (searchVal, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    setSearchTerm(searchVal);
    // isFetcingUserFilter.current = false
    console.log(searchTerm, searchVal, results)
  }
  const handleOnSelect = (item) => {
    console.log('handleOnSelect', item)
    setNewUser({ ...newUser, ['reportsTo']: item.id });
    // handleChange
  };

  // Get admin settings values for dynamic font sizes.
 const hdngFontSize = getValue('headingFontSize');

  const lblFontSize = getValue('labelFontSize' );
  const gender = ["Male", "Female", "Other"];
  
  const [errors, setErrors] = useState({});

  // Fetch agencies and branches data.
  useEffect(() => {
    if ( !isFetcingBranch.current ) {
      dispatch(fetchAgencies());
      dispatch(fetchBranches());
      // dispatch(fetchAdminSetting());
      isFetcingBranch.current = true
    }
  }, [status, dispatch]);

  // Reset branch fetching flag after status update.
  useEffect(() => {
    if ( status === 'succeeded' || status === 'error' ) isFetcingBranch.current = false
}, [status]);

// Reset user filter fetching flag after user state update.
useEffect(() => {
  if ( stateUsers === 'succeeded' || stateUsers === 'error' ) isFetcingUserFilter.current = false
}, [stateUsers]);


// Validation functions for form fields.
  const validateName = (name) =>/^[A-z]{2,25}$/.test(name.replace(/ /g, ''));

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(email).toLowerCase());

  const validatePhone = (phone) => /^[6-9]\d{9}$/.test(phone);

  const validatePAN = (pan) => /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(pan);

  const validateMaxCashLimit = (maxCashLimit) => {
    return /^[0-9]+$/.test(maxCashLimit)>= 0 && parseInt(maxCashLimit) <= 100000;
  }

    // Date of Birth validation 
  const validateDOB = (dob) => {
    const today = new Date();
    const dobDate = new Date(dob);
    let age = today.getFullYear() - dobDate.getFullYear();
    const monthDifference = today.getMonth() - dobDate.getMonth();

    
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < dobDate.getDate())) {
      age--;
    }

    
    return age >= 18 && age <= 100;
  };


    // Form validation logic, checks each field and updates error state.
  const validateForm = () => {
    let formIsValid = true;
    let validationErrors = {};

    if (!newUser.firstname || !validateName(newUser.firstname)) {
      formIsValid = false;
      validationErrors.firstname = 'Please enter a valid first name with only letters (2-25 characters).';
    }
  
 
    if (!newUser.lastname || !validateName(newUser.lastname)) {
      formIsValid = false;
      validationErrors.lastname = 'Please enter a valid last name with only letters (2-25 characters).';
    }    

    if (!newUser.mobile || !validatePhone(newUser.mobile)) {
      formIsValid = false;
      validationErrors.mobile = 'Please enter a valid 10-digit phone number.';
    }

    if (!newUser.email || !validateEmail(newUser.email)) {
      formIsValid = false;
      validationErrors.email = 'Please enter a valid email address.';
    }

    if (!newUser.pan || !validatePAN(newUser.pan)) {
      formIsValid = false;
      validationErrors.pan = 'Please enter a valid PAN number (e.g., ABCDE1234F).';
    }

    if (!newUser.dob || !validateDOB(newUser.dob)) {
      formIsValid = false;
      validationErrors.dob = 'Please enter a valid DOB. The user must be above 18 years.';
    }

    if (!newUser.maxCashLimit || !validateMaxCashLimit(newUser.maxCashLimit)) {
      formIsValid = false;
      validationErrors.maxCashLimit = 'Please enter a valid Max Cash Limit. It should be up to ₹1,00,000.';
    }

    setErrors(validationErrors);
    return formIsValid;
  };

   // Handle form submission
  const onSubmit = (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }

    const requestBody = {
      contacts: [
        {
          firstname: newUser.firstname, // Assuming name format: "First Last" name.split(' ')[0]
          lastname: newUser.lastname,  // Adjust if needed newUser.lastName, name.split(' ')[1]
          mobile: newUser.mobile,
          email: newUser.email,
          gender: newUser.gender.toLowerCase(),
          dateOfBirth: newUser.dob,
          pan_number: newUser.pan,
          agency: newUser.agency,
          branchId: newUser.branch,
          maxCashLimit:newUser.maxCashLimit,
          reportstoid: newUser.reportsTo,
          
        }
      ]
    };
    // console.log('requestBody', requestBody)
    handleSubmit(requestBody);
    handleReset();
  };


  // console.log('status users', stateUsers, users)
  return (
    <Modal show={show} onHide={handleClose} centered dialogClassName="modal-custom">
      <Modal.Header closeButton style={{ display: 'flex', justifyContent: 'center' }}>
        <Modal.Title style={{ flex: 1, textAlign: 'center', fontSize: hdngFontSize + "px" }}>Add User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
           {/* Agency and Branch dropdown */}
          <Row className="mb-3">
            <Form.Group as={Col} className="mb-3">
              <Form.Label style={{ fontSize: lblFontSize + "px"}}>Agency<span className='mandatory'>*</span></Form.Label>
              <Form.Control
                as="select"
                name="agency"
                value={newUser.agency}
                onChange={handleChange}
                required
              >
                <option value="">Select Agency</option>
                {(Array.isArray(agencies) ? agencies : []).map((agency) => (
                  <option key={agency.sfid} value={agency.sfid}>
                    {agency.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} className="mb-3">
              <Form.Label style={{ fontSize: lblFontSize + "px"}}>Branch<span className='mandatory'>*</span></Form.Label>
              <Form.Control
                as="select"
                name="branch"
                value={newUser.branch}
                onChange={handleChange}
                required
              >
                <option value="">Select Branch</option>
                {(Array.isArray(branches) ? branches : []).map((branch) => (
                  <option key={branch.sfid} value={branch.sfid}>
                    {branch.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Row>
          {/* Reports To field with search autocomplete */}
          <Row>
          <div style={{ position: 'relative' }} className='mb-3'>
          <Form.Group as={Col}>
              <Form.Label style={{ fontSize: lblFontSize + "px"}}>Report To<span className='mandatory'>*</span></Form.Label>
              <ReactSearchAutocomplete
                items={(users || []).filter(user => user.is_active__c === true)
                  .map(activeUser => ({
                    id: activeUser.sfid,
                    name: `${activeUser?.name} (${activeUser.contactAccount?.name}, ${activeUser.Banker?.BranchUnitBusinessMember?.branchUnit?.name})`,
                  }))}
                fuseOptions={{ keys: ["name"] }} 
                resultStringKeyName="name"
                value={newUser.reportsTo}
                onSearch={handleOnSearch}
                onSelect={handleOnSelect}
                autoFocus
                placeholder="Search user"
              />
            </Form.Group>
            </div>
</Row>
          <Row className="mb-3">
            <Form.Group as={Col} className="mb-3">
              <Form.Label style={{ fontSize: lblFontSize + "px"}}>First Name<span className='mandatory'>*</span></Form.Label>
              <Form.Control
                type="text"
                name="firstname"
                value={newUser.firstname}
                onChange={handleChange}
                required
              />
               {errors.firstname && <div className="text-danger">{errors.firstname}</div>}
            </Form.Group>
            <Form.Group as={Col} className="mb-3">
              <Form.Label style={{ fontSize: lblFontSize + "px"}}>Last Name<span className='mandatory'>*</span></Form.Label>
              <Form.Control
                type="text"
                name="lastname"
                value={newUser.lastname}
                onChange={handleChange}
                required
              />
              {errors.lastname && <div className="text-danger">{errors.lastname}</div>}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} className="mb-3">
              <Form.Label style={{ fontSize: lblFontSize + "px"}}>Email<span className='mandatory'>*</span></Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={newUser.email}
                onChange={handleChange}
                required
              />
              {errors.email && <div className="text-danger">{errors.email}</div>}
            </Form.Group>
            <Form.Group as={Col} className="mb-3">
              <Form.Label style={{ fontSize: lblFontSize + "px"}}>Gender<span className='mandatory'>*</span></Form.Label>
              <Form.Control
                as="select"
                name="gender"
                value={newUser.gender}
                onChange={handleChange}
                required
              >
                <option value="">Select Gender</option>
                {gender.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} className="mb-3">
              <Form.Label style={{ fontSize: lblFontSize + "px"}}>Mobile<span className='mandatory'>*</span></Form.Label>
              <Form.Control
                type="text"
                name="mobile"
                value={newUser.mobile}
                onChange={handleChange}
                required
              />
              {errors.mobile && <div className="text-danger">{errors.mobile}</div>}
            </Form.Group>
            <Form.Group as={Col} className="mb-3">
              <Form.Label style={{ fontSize: lblFontSize + "px"}}>PAN No.<span className='mandatory'>*</span></Form.Label>
              <Form.Control
                type="text"
                name="pan"
                value={newUser.pan}
                onChange={handleChange}
                required
              />
              {errors.pan && <div className="text-danger">{errors.pan}</div>}
            </Form.Group>
          </Row>
          <Row className="mb-3">
          <Col xs={6}>
              <Form.Group className="mb-3">
                <Form.Label style={{ fontSize: lblFontSize + "px"}}>Max Cash Limit<span className='mandatory'>*</span></Form.Label>
                <Form.Control
                  type="number"
                  name="maxCashLimit"
                  value={newUser.maxCashLimit}
                  onChange={handleChange}
                  min="0"
                  max="100000"
                 
                  required
                />
                {errors.maxCashLimit && <div className="text-danger">{errors.maxCashLimit}</div>}
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group className="mb-3">
                <Form.Label style={{ fontSize: lblFontSize + "px"}}>Date of Birth<span className='mandatory'>*</span></Form.Label>
                <Form.Control
                  type="date"
                  name="dob"
                  value={newUser.dob}
                  onChange={handleChange}
                  required
                />
                {errors.dob && <div className="text-danger">{errors.dob}</div>}
              </Form.Group>
            </Col>
          </Row>
          <Button
            variant="primary"
            type="submit"
            style={{ width: '25%', marginTop: '20px',backgroundColor: adminSettings?.properties?.primaryButtonColor  }}
            className="text-center d-block mx-auto"
            
          >
            Save
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default AddUserModal;
