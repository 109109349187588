// Imports
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDispositionList, updateDispositionList } from '../features/dispositionSlice';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import Navbar from './Navbar';
import axios from 'axios';

import { triggerToast } from '../utils/toast';
import { fetchAdminSetting } from '../features/settingSlice';
import { setValue, getToken, getValue } from '../utils/auth';

// Base URL for API requests
// const base_url = 'https://sf-collection-api-0f31b037d478.herokuapp.com';
const base_url = process.env.REACT_APP_API_BASE_URL;

const DispositionList = () => {
  const dispatch = useDispatch();
  const [selectedDisposition, setSelectedDisposition] = useState([]);
  const dispositionList = useSelector((state) => state.dispositionList.dispositionList);
  // const dispositionStatus = useSelector((state) => state.dispositionList.dispositionUpdate);
  const adminSettings = useSelector((state) => state.setting.adminSettings);
 
  // Fetch admin settings
  useEffect(() => {
    dispatch(fetchAdminSetting());
  }, [dispatch]);

  // Fetch disposition list
  useEffect(() => {
     dispatch(fetchDispositionList());
  }, [dispatch]);

   // Update selected dispositions
  useEffect(() => {

    (dispositionList || []).map((obj, index) =>{
      if(obj.isactive){
        //return [...selectedDisposition, obj.id];
        //setSelectedDisposition([...selectedDisposition, obj.id]);
        selectedDisposition.push(obj.id);        
        //console.log('disposition', obj.id);
        //console.log('disposition selectedDisposition', selectedDisposition);
      }else {
        //setSelectedDisposition(selectedDisposition.filter(id => id !== obj.id));
      }
    });
    //console.log('state.dispositionList', dispositionList);
  }, [dispositionList]);

  // const handleSearch = (e) => {
  //   e.preventDefault();
  //   // dispatch(searchLoginHistory({ searchTerm, loginDate, logoutDate }));
  // };


   // Handle form submission
  const handleSubmit = async(e) =>{
    e.preventDefault();
    try{
      // console.log('selectedDisposition',selectedDisposition)
      const activeDispositions = [];
      {(selectedDisposition || []).map((id, index) => (
        activeDispositions.push(id)
        //activeDispositions.push({"id":id,"activeStatus":true})
      ))};
      //console.log('activeDispositions',activeDispositions);
      const requestBody = JSON.parse('{"ids":['+activeDispositions+'],"activeStatus":true}');
      // console.log('requestBody',requestBody);
      // return;
      const response = await axios.post(`${base_url}/api/visits/updateDispositionActiveStatus`, requestBody, {
      headers: { 
        Authorization: `Bearer ${getToken()}`,
      }});

      
    
      dispatch(fetchDispositionList());
      //console.log("dispositionStatus",dispositionStatus);
      triggerToast(response.data.message, 'success');
      //console.log("response",response.data.message);  
    
    } catch (error) {
      triggerToast(error.message, 'error');
      // console.error('API Error:', error.message);
    }
  }
      
      setValue('headingFontSize', adminSettings?.properties?.headingFontSize);
      setValue('subHeadingFontSize', adminSettings?.properties?.subHeadingFontSize);



        const hdngFontSize = getValue('headingFontSize');
        const subHdngFontSize = getValue('subHeadingFontSize')
        

  // Handle selection of dispositions
  const selectList = (e, dispId) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      //selectedDisposition.push(dispId);
      setSelectedDisposition([...selectedDisposition, dispId]);
    } else {
      setSelectedDisposition(selectedDisposition.filter(id => id !== dispId));
    }
    //setSelectedDisposition(Array.from(new Set(selectedDisposition)));
    //console.log('FINAL selectedDisposition', selectedDisposition);
  };

  return (
    <div className="home-container d-flex flex-column flex-lg-row">
      <Navbar />
      <main className="content flex-grow-1">
        <h3 className="mb-3" style={{fontSize: hdngFontSize + "px"}}>Disposition List</h3>
        <Container className="bg-white rounded-2" style={{ padding: '20px', justifyContent: 'center'  }}>
          <Form onSubmit={handleSubmit}>
            <Row className="align-items-center mb-3" style={{ display: 'flex', justifyContent: 'center' }}>
            
              <Col sm={6}>
              <h6 style={{fontSize: subHdngFontSize + "px"}}>List of Disposition Items</h6>
              <p>Please choose to activate or deactivate.</p>
              {(dispositionList || []).map((dispositionList, index) => (
                  <Form.Check // prettier-ignore
                    key={dispositionList.id}
                    type="checkbox"
                    //checked={dispositionList.isactive}
                    defaultChecked={dispositionList.isactive}
                    onChange={(e) => selectList(e, dispositionList.id)}
                    label={dispositionList.disposition}
                  />
                ))}
                
            <Button
                variant="primary"
                type="submit"
                style={{  marginTop: '30px', backgroundColor: adminSettings?.properties?.primaryButtonColor }}
                className="text-center "
              
              >Submit</Button>
            </Col>
            </Row>
          </Form>

      
        </Container>
      </main>
    </div>
  );
};

export default DispositionList;
