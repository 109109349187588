// features/deviceHistorySlice.js
// Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken, logoutUser } from '../utils/auth';

// Base URL for API requests
// const baseURL = 'https://sf-collection-api-0f31b037d478.herokuapp.com';
const baseURL = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  dispositionList: [],
  dispositionUpdate: [],
  status: 'idle',
  error: null,
};

// Thunk for fetching disposition list
export const fetchDispositionList = createAsyncThunk('dispositionlist/fetchDispositionList', async () => {
  const response = await axios.get(`${baseURL}/api/visits/getAllDispositions`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  return response.data;
});

// Thunk for updating disposition list
export const updateDispositionList = createAsyncThunk('dispositionlist/updateDispositionList', async (requestBody) => {
  const response = await axios.post(`${baseURL}/api/visits/updateDispositionActiveStatus`, requestBody, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  // console.log('updateDispositionList',requestBody)
  return response.data;
});

// Slice for managing disposition list state
const dispositionListSlice = createSlice({
  name: 'dispositionList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDispositionList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDispositionList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.dispositionList = action.payload;
      })
      .addCase(fetchDispositionList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        if(action.error.code === "ERR_BAD_REQUEST"){
          logoutUser();
        }
      })

      .addCase(updateDispositionList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateDispositionList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.dispositionUpdate = action.payload;
        // console.log('action.payload', action.payload)
      })
      .addCase(updateDispositionList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        //state.dispositionUpdate = action.error;
        if(action.error.code === "ERR_BAD_REQUEST"){
          logoutUser();
        }
      });
  },
});

export default dispositionListSlice.reducer;
