// Imports
import React, { useEffect, useState, forwardRef, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoginHistory, searchLoginHistory } from '../features/loginHistorySlice';
import { Table, Form, Button, Container, Row, Col, InputGroup, Pagination } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Navbar from './Navbar';
import { IoIosSearch } from 'react-icons/io';
import { FaChevronLeft, FaChevronRight, FaCalendarAlt,FaSortUp, FaSortDown } from 'react-icons/fa';
import { setValue,getValue } from '../utils/auth';
// import { fetchAdminSetting } from '../features/settingSlice';
import moment from 'moment-timezone';

// Custom date input component for DatePicker
const CustomDateInput = forwardRef(({ value, onClick, placeholder }, ref) => (
  <InputGroup>
    <Form.Control
      value={value}
      onClick={onClick}
      ref={ref}
      placeholder={placeholder}
    />
    <InputGroup.Text onClick={onClick}>
      <FaCalendarAlt />
    </InputGroup.Text>
  </InputGroup>
));

const timeZone = process.env.REACT_APP_TIMEZONE

// Format date and time for display
const formatDateTime = (date) => {
  return moment(date).format('DD MMM YYYY, h:mm:ss A');
};




// Local States
const LoginHistory = () => {
  const dispatch = useDispatch();
  const isFetcing = useRef( false );
  const data = useSelector((state) => state.loginHistory);
  const status = data.status;
  const usersPerPage = data.loginHistory.pageSize;
  const totalPages = data.loginHistory.totalPages;

  setValue('loginhistory_page_size', usersPerPage ?? 10);

  const [searchTerm, setSearchTerm] = useState('');
  const [loginDate, setLoginDate] = useState(new Date());
  const [logoutDate, setLogoutDate] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(1);
  const [sortKey, setSortKey] = useState('login_date_time__c');
  const [sortDirection, setSortDirection] = useState(false); 
  const adminSettings = useSelector((state) => state.setting.adminSettings);
  setValue('loginhistory_cur_page', currentPage);
  const pageIndex = (currentPage - 1) * usersPerPage;

  useEffect(() => {
    // dispatch(fetchAdminSetting());
  }, [dispatch]);

   // Get admin settings values for dynamic font sizes.
  setValue('labelFontSize', adminSettings.labelFontSize);

  setValue('tableHeadingFontSize', adminSettings?.properties?.tableHeadingFontSize);
  setValue('tableDetailsFontSize', adminSettings?.properties?.tableDetailsFontSize);
  setValue('headingFontSize', adminSettings?.properties?.headingFontSize);
 


  const lblFontSize = getValue('labelFontSize' );
  // const icnSize = getValue('iconFontSize' );
  const tblHeadingFontSize = getValue('tableHeadingFontSize');
 const tblDetailsFontSize = getValue('tableDetailsFontSize');
 const hdngFontSize = getValue('headingFontSize');
//  const subHdngFontSize = getValue('subHeadingFontSize')
  
  useEffect(() => {
    if ( !isFetcing.current ) {
    dispatch(fetchLoginHistory({ 
      sort: sortDirection, 
      sortKey: sortKey 
    }));
    isFetcing.current = true
  }
  }, [searchTerm,dispatch, status, sortDirection, sortKey]);

  useEffect(() => {
    if ( status === 'succeeded' || status === 'error' ) isFetcing.current = false
}, [status]);

 // Format date for API request
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

   // Handle search functionality
  const handleSearch = (e) => {
    e.preventDefault();
    setValue('loginhistory_cur_page', 1);
    setCurrentPage(1);
    
    let filterObject = { searchTerm };
    if (loginDate) filterObject.loginDate = formatDate(loginDate);
    if (logoutDate) filterObject.logoutDate = formatDate(logoutDate);
    dispatch(searchLoginHistory(filterObject));
  };

    // Handle pagination
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setValue('loginhistory_cur_page', pageNumber);
  };

  
  const startPage = Math.max(currentPage, 1);
  const endPage = Math.min(startPage + 2, totalPages);
  const pageNumbers = [];
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

   // Handle sorting
  const handleSort = (key) => {
   
    if (sortKey === key) {
      setSortDirection(!sortDirection); 
    } else {
     
      setSortKey(key);
      setSortDirection(true);
    }
  
    
    // dispatch(fetchLoginHistory({ 
    //   sort: sortDirection, 
    //   sortKey: key 
    // }));
  };

  return (
    <div className="home-container d-flex flex-column flex-lg-row">
      <Navbar />
      <main className="content flex-grow-1">
        <h3 className="mb-3" style={{fontSize: hdngFontSize + "px"}}>Login History</h3>
        <Container className="bg-white rounded-2" style={{ padding: '20px' }}>
        <Form onSubmit={handleSearch}>
  <Row className="align-items-center mb-3 gx-2">
    <Col xs={12} sm={6} md={5} lg={4}>
      <InputGroup>
        <InputGroup.Text className="bg-white">
          <IoIosSearch />
        </InputGroup.Text>
        <Form.Control
          type="text"
          placeholder="Search by Name or Mobile"
          className="text-center"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </InputGroup>
    </Col>

    <Col xs="auto" className="d-flex align-items-center ms-3 ">
      <Form.Label className="mb-0" style={{ fontSize: lblFontSize + "px"}}>From Date:</Form.Label>
    </Col>
    <Col xs={6} sm={3} md={2}>
      <DatePicker
        selected={loginDate}
        onChange={(date) => setLoginDate(date)}
        maxDate={logoutDate}
        customInput={<CustomDateInput placeholder="From Date" />}
      />
    </Col>

    <Col xs="auto" className="d-flex align-items-center ms-3 ">
      <Form.Label className="mb-0" style={{ fontSize: lblFontSize + "px"}}>To Date:</Form.Label>
    </Col>
    <Col xs={6} sm={3} md={2}>
      <DatePicker
        selected={logoutDate}
        onChange={(date) => setLogoutDate(date)}
        minDate={loginDate}
        customInput={<CustomDateInput placeholder="To Date" />}
      />
    </Col>

    <Col xs={12} sm="auto" className="d-flex justify-content-end mt-2 mt-sm-0 ms-5">
      <Button type="submit" style={{ backgroundColor: adminSettings?.properties?.primaryButtonColor }} > 
        Search
      </Button>
    </Col>
  </Row>
</Form>




          {data.status === 'loading' ? (
            <p>Loading...</p>
          ) : data.status === 'failed' ? (
            <p>Error: {data.error}</p>
          ) : (
            <div>
              <Table striped hover responsive>
                <thead>
                  <tr>
                    <th className='text-center' style={{fontSize: tblHeadingFontSize + "px"}}>Sr. No.</th>
                    <th
        className="text-start"
        style={{fontSize: tblHeadingFontSize + "px", cursor: 'pointer'}}
        onClick={() => handleSort('name')}
      >
        Name
        <span className="ms-2 align-items-center">
        {sortKey === 'name' && sortDirection ? <FaSortUp /> : <FaSortDown />}
        </span>
      </th>
      <th
        className="text-center"
        style={{fontSize: tblHeadingFontSize + "px", cursor: 'pointer'}}
        onClick={() => handleSort('mobilephone')}
      >
        Mobile
        <span className="ms-2 align-tems-center">
        {sortKey === 'mobilephone' && sortDirection ? <FaSortUp /> : <FaSortDown />}
        </span>
      </th>
                    <th className='text-center' style={{fontSize: tblHeadingFontSize + "px"}}>Logged In</th>
                    <th className='text-center' style={{fontSize: tblHeadingFontSize + "px"}}>Device Name</th>
                    <th className='text-center' style={{fontSize: tblHeadingFontSize + "px"}}>OS Version</th>
                    <th className='text-center' style={{fontSize: tblHeadingFontSize + "px"}}>Login IP</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(data.loginHistory.loginHistory) &&
                    data.loginHistory.loginHistory.map((record, index) => (
                      <tr key={record.id}>
                        <td className='text-center' style={{fontSize: tblDetailsFontSize + "px"}}>{pageIndex + index + 1}</td>
                        <td className='text-start' style={{fontSize: tblDetailsFontSize + "px"}}>{record.contactLoginHistory.name}</td>
                        <td className='text-center ' style={{fontSize: tblDetailsFontSize + "px"}}>{record.contactLoginHistory.mobilephone}</td>
                        <td className='text-center' style={{fontSize: tblDetailsFontSize + "px"}}> {formatDateTime(record['login_date_time__c'])}</td>
                        <td className='text-center' style={{fontSize: tblDetailsFontSize + "px"}}>{record.deviceLoginHistory.model__c}</td>
                        <td className='text-center' style={{fontSize: tblDetailsFontSize + "px"}}>{record.deviceLoginHistory.os_version__c}</td>
                        <td className='text-center' style={{fontSize: tblDetailsFontSize + "px"}}>{record['login_ip__c']}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>

              <Pagination className="justify-content-end mb-3 me-2">
                <Pagination.Prev
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <FaChevronLeft className="text-dark" />
                </Pagination.Prev>

                {pageNumbers.map((page) => (
                  <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => paginate(page)}
                    className="ms-1 me-1"
                  >
                    {page}
                  </Pagination.Item>
                ))}

                <Pagination.Next
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  <FaChevronRight className="text-dark" />
                </Pagination.Next>
              </Pagination>
            </div>
          )}
        </Container>
      </main>
    </div>
  );
};

export default LoginHistory;
