// features/loginHistorySlice.js
// Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken, logoutUser, getValue } from '../utils/auth';

// Base URL for API requests
// const baseURL = 'https://sf-collection-api-0f31b037d478.herokuapp.com';
const baseURL = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  loginHistory: [],
  status: 'idle',
  error: null,
};

// Thunk for fetching login history with optional sorting
export const fetchLoginHistory = createAsyncThunk('loginHistory/fetchLoginHistory', async (params ) => {
  let pageNum = getValue('loginhistory_cur_page');
  let size = getValue('loginhistory_page_size');
  let sort = params.sort ?? true;
  let sortKey = params.sortKey ?? 'name';
  let otherParam = '';

  if (sort !== '') otherParam += `&sort=${sort}`;
  if (sortKey !== '') otherParam += `&sortKey=${sortKey}`;
  const response = await axios.get(`${baseURL}/api/user/getAllContactsLoginHistory?page=${pageNum}&pageSize=${size}${otherParam}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  return response.data;
});

// Thunk for searching login history with optional filters
export const searchLoginHistory = createAsyncThunk('loginHistory/searchLoginHistory', async (params) => {
  let pageNum = getValue('loginhistory_cur_page')??1;
  let size = getValue('loginhistory_page_size')??10;
  let searchTerm = params.searchTerm??'';
  let loginDate = params.loginDate??'';
  let logoutDate = params.logoutDate??'';
  const response = await axios.get(`${baseURL}/api/user/getAllContactsLoginHistory?page=${pageNum}&pageSize=${size}&search=${searchTerm}&fromDate=${loginDate}&toDate=${logoutDate}`, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  return response.data;
});

// Slice for managing login history state
const loginHistorySlice = createSlice({
  name: 'loginHistory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoginHistory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLoginHistory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.loginHistory = action.payload;
      })
      .addCase(fetchLoginHistory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        if(action.error.code === "ERR_BAD_REQUEST"){
          logoutUser();
        }
      })
      .addCase(searchLoginHistory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(searchLoginHistory.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.loginHistory = action.payload;
      })
      .addCase(searchLoginHistory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        if(action.error.code === "ERR_BAD_REQUEST"){
          logoutUser();
        }
      });
  },
});

export default loginHistorySlice.reducer;
